export const generos = [
    {value: 10, label: "Hombre"},
    {value: 20, label: "Mujer"}
];
export const regimenes = [
    {value: 10, label: "Pequeño Contribuyente"},
    {value: 20, label: "Factura Especial"},
    {value: 30, label: "Pagos Trimestrales"},
    {value: 40, label: "Pagos Directos"},
    {value: 50, label: "Exportador"},
    {value: 60, label: "Producción Interna"},
];
export const tipoUsoEmail = [
    {value: 10, label: "RECEPCION_ERSA"},
];

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCteOPje5Zw_L8NHEG4IIKEdil16akdyTk';

export const meses = [
    {value: 1, label: "Enero"},
    {value: 2, label: "Febrero"},
    {value: 3, label: "Marzo"},
    {value: 4, label: "Abril"},
    {value: 5, label: "Mayo"},
    {value: 6, label: "Junio"},
    {value: 7, label: "Julio"},
    {value: 8, label: "Agosto"},
    {value: 9, label: "Septiembre"},
    {value: 10, label: "Octubre"},
    {value: 11, label: "Noviembre"},
    {value: 12, label: "Diciembre"},
];

// export const ANIO_INICIAL = 2020;
export const ANIO_INICIAL = 2015;


// Constantes para el grupo de tipo de pago
export const tipo_pago = [
    {value: 10, label: "Quetzales"},
    {value: 20, label: "Dolares"},
    {value: 30, label: "Trading"}
];

export const PRODUCT = {
    "LATEX": 10,
    "SOLIDOS": 20,
    "SKIM": 30,
}