import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
import { months } from '../../../common/components/Utils/months';
const alerta = require('assets/img/icons/activo.png');

let url = 'shopping';
let form = 'ShoppingForm';
// ------------------------------------
// Constants
// ------------------------------------

export const constants = {
    LOADER: 'LOADER_ADMINISTRATIVOS',
    DATA: 'DATA_ADMINISTRATIVOS',
    ITEM_DATA: 'ITEM_ADMINISTRATIVOS',
    PAGE: 'PAGE_ADMINISTRATIVOS',
    ORDERING: 'ORDERING_ADMINISTRATIVOS',
    SEARCH: 'SEARCH_ADMINISTRATIVOS',
    TAB_EN_CURSO: 'TAB_EN_CURSO',
    TAB_PROYECCIONES_ANUALES: 'TAB_PROYECCIONES_ANUALES',
    PAGE_EN_CURSO: 'PAGE_EN_CURSO',
    PAGE_ANUALES: 'PAGE_ANUALES',
    LATEX_TABLE: 'LATEX_TABLE',
    CHIPA_TABLE: 'CHIPA_TABLE',
    SET_STEP: 'SET_STEP_SHOPPING'
}

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setItem = item => ({
    type: constants.ITEM_DATA,
    item,
});
const setStep = (step) => ({
    type: constants.SET_STEP,
    step,
});

const confirm_shopping_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setStep(1))
            dispatch(read())
        } else {
            dispatch(read())
            dispatch(setStep(1))
        }
    });
}

// -----------------------------------
// Actions
// -----------------------------------


const read = year => (dispatch) => {
    dispatch(setLoader(true));
    const params = { year: year };

    api.get(`${url}/get_shopping`, params)
        .then((response) => {
            const { results, dollar_exchange } = response;

            dispatch(setData(response));
            dispatch(setItem(dollar_exchange));
            const latex_table = processDataForLatexTable(response);
            const chipa_table = processDataForChipaTable(response);
            dispatch({
                type: constants.LATEX_TABLE,
                latex_table
            });
            dispatch({
                type: constants.CHIPA_TABLE,
                chipa_table
            });
            const formData = {
                ...results,
                latex_table: latex_table,
                chipa_table: chipa_table
            };
            dispatch(initializeForm(form, formData));
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en lectura de datos', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const processDataForLatexTable = (data) => {
    const { results } = data
    const drc_latex_initial = results.drc_promedio_general_latex.generic_row[0] || []
    // var production_exportable = []
    const latex_table = [
        { name: 'Compra de látex (costo neto)', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'kILOS hUMEDOS', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'drc promedio general', data: drc_latex_initial, is_drc: true },
    ];
    latex_table.forEach(item => {
        item.total = 0;
        for (let mes in item.data) {
            item.total += item.data[mes];
        }
        Object.assign(item, item.data);
    });

    return latex_table;
}
const processDataForChipaTable = (data) => {
    const { results } = data
    const drc_chipa_initial = results.drc_promedio_general_chipa.generic_row[0] || []

    const chipa_table = [
        { name: 'Compra de chispa (costo neto)', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'kILOS hUMEDOS', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'drc promedio general', data: drc_chipa_initial, is_drc: true },
        { name: 'kilos secos equivalentes', enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'precio por kilo seco (sin iva)', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'Precio por kilo seco (CON IVA)', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'PAGO NETO POR COMPRAS SIN IVA', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'PAGO BRUTO POR COMPRAS CON IVA', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },
        { name: 'IMPORTE DEL IVA PAGADO', total: 0, enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0, junio: 0, julio: 0, agosto: 0, septiembre: 0, octubre: 0, noviembre: 0, diciembre: 0 },

    ];
    chipa_table.forEach(item => {
        item.total = 0;
        for (let mes in item.data) {
            item.total += item.data[mes];
        }
        Object.assign(item, item.data);
    });

    return chipa_table;
}

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`administrative/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm(form, response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        confirm_shopping_save(dispatch);
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: constants.TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getAdministrativos())
    } else {
        dispatch(getAdministrativos())
    }
};

export const actions = {
    editar,
    read,
    getDollar,
    setTab,
    processDataForLatexTable,
    setStep,

};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [constants.PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [constants.TAB_EN_CURSO]: (state, { tab_encurso }) => {
        return {
            ...state,
            tab_encurso,
        };
    },
    [constants.TAB_PROYECCIONES_ANUALES]: (state, { tab_anuales }) => {
        return {
            ...state,
            tab_anuales,
        };
    },
    [constants.LATEX_TABLE]: (state, { latex_table }) => {
        return {
            ...state,
            latex_table,
        };
    },
    [constants.CHIPA_TABLE]: (state, { chipa_table }) => {
        return {
            ...state,
            chipa_table,
        };
    },
    [constants.SET_STEP]: (state, { step }) => {
        return {
            ...state,
            step,
        };
    },

};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    latex_table: {},
    chipa_table: {},
    item: {},
    tab: 'EN_CURSO',
    step: 1,

};
export default handleActions(reducers, initialState)