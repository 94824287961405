import React, { useEffect } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderNumberKG, renderField, renderDollar } from '../../../../../Utils/renderField/renderField';
import { months as meses } from '../../../../../Utils/months';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';


const TableRow = props => {
    const { system, index, fields, dollar_exchange } = props;

    const months = meses
    const calculateTotalForRow = (isDollar) => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderField}
                        // isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {months.map((month, monthIndex) => (
                    <td key={month} style={{ width: "6.9%" }} className="custom-cell text-center">
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={renderDollar}
                            className="form-control"
                            placeholder={'$ 0.00'}
                        />
                    </td>
                ))}
                <td style={{ width: "6.9%" }} className="custom-cell text-center">
                    $ {new Intl.NumberFormat('es-GT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculateTotalForRow())}
                </td>
                <td className="text-center sin-borde-top" style={{ width: "10%", position: 'relative' }}>
                    <div>
                        <img src={require('assets/img/icons/Accion-Eliminar.svg')} alt="Borrar"
                            title="Borrar" className="action_img"
                            onClick={() => {
                                fields.remove(index)
                            }}
                        />
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};


const GenericTable = props => {
    const { fields, dollar, values_kg, allValues } = props;
    const dollar_exchange = dollar

    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));



    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    {columnas.map(columna => columna)}
                                    <th style={{ width: "6.9%" }}>TOTAL</th>
                                    <th style={{ width: "6.9%" }}>ACCIONES</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    dollar_exchange={dollar_exchange}
                                    kg_proyectados={values_kg}

                                />)}
                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES Q</td>
                                    {meses.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center">TOTALES $</td>
                                    {meses.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={() => fields.push({ isNew: true })}>
                    AGREGAR
                </div>
            </div>
        </div>

    );
}

const SalesDivers = props => {
    return (
        <div>
            <form onSubmit={props.handleSubmit}>
                <FieldArray
                    name='sales_budget_divers'
                    component={GenericTable}
                    props={props}
                />
                <div className='buttons-box mt-5'>
                    <button type="submit"
                        className="btn btn-primary m-4 btn-modal"
                    >
                        CONFIRMAR
                    </button>
                </div>
            </form>
        </div>
    );
}

const formName = 'SalesDiversForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const categoryBudget = selector(state, 'sales_budget_divers') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: formName,
    })(SalesDivers)
);