import React from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import { Field } from 'redux-form';
import { renderNumberPercentage } from '../../Utils/renderField/renderField';

const CreditoFiscal = props => {
    const { change, parameterTaxCredit } = props;

    // Función para manejar cambios en los campos y calcular el porcentaje restante
    const handlePercentageChange = (e, fieldName, relatedFieldName) => {
        const value = parseFloat(e) || 0;
        let remainingPercentage = 100 - value;
        change(fieldName, value);
        change(relatedFieldName, remainingPercentage);
    };

    // Función para manejar la distribución entre exportado y contribuyentes especiales

    // Habria que buscar que es lo que causa usa suma que no deberia, al parecer debe transformarse en numerico. 

    const handleIvaRetentionDistributionChange = (e, relatedField) => {
        const value = parseFloat(e) || 0;
        const relatedFieldValue = parseFloat(parameterTaxCredit[relatedField]) || 0;
        const total = value + relatedFieldValue;

        const total_no_retenido = 100 - total
        change(e, value); // Actualiza el campo actual
        change('parameter_tax_credit.iva_subject_to_withholding', total);
        change('parameter_tax_credit.iva_not_withheld', total_no_retenido)

    };

    return (
        <div>
            <CardEmpty>
                <h2 className='titulo_parrafos'>Crédito fiscal</h2>
                <div className='fn-info'>
                    <div className="row col-md-12 p-0">
                        {/* Sección izquierda */}
                        <div className="col-md-6 p-0">
                            <span>Distribución de compras por FE y FN</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje de factura normal</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.normal_invoice"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handlePercentageChange(e, 'parameter_tax_credit.normal_invoice', 'parameter_tax_credit.special_invoice')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="credito1" className="m-0">Porcentaje de factura especial</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.special_invoice"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 p-0">
                            <span>Retenciones de factura normal</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje FN genera retención</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.if_it_generates_retention"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handlePercentageChange(e, 'parameter_tax_credit.if_it_generates_retention', 'parameter_tax_credit.does_not_generate_retencion')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje FN No genera retención</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.does_not_generate_retencion"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='fn-info'>
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <span>Distribución de IVA sujeto a retención</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje exportado decreto 29-89</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.exported_decree_29_89"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleIvaRetentionDistributionChange(e, 'special_taxpayers')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje contribuyentes especiales</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.special_taxpayers"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handleIvaRetentionDistributionChange(e, 'exported_decree_29_89')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <span>Retención de IVA</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje de IVA sujeto a retención</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.iva_subject_to_withholding"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="credito1" className="m-0">Porcentaje de IVA no retenido</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.iva_not_withheld"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='fn-info'>
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <span>Distribución de operaciónes</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje retención Op. Exportación</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.export_op_retention"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handlePercentageChange(e, 'parameter_tax_credit.export_op_retention', 'parameter_tax_credit.local_op_retention')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="credito1" className="m-0">Porcentaje retención Op. Locales</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.local_op_retention"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 p-0">
                            <span>Distribución por regímen</span>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje de regímen genera</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        name="parameter_tax_credit.special_regime"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => handlePercentageChange(e, 'parameter_tax_credit.special_regime', 'parameter_tax_credit.normal_regime')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="nombre" className="m-0">Porcentaje de regímen especial</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field
                                        disabled
                                        name="parameter_tax_credit.normal_regime"
                                        component={renderNumberPercentage}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardEmpty>
        </div>
    );
}

export default CreditoFiscal;
