import { validate, validators } from 'validate-redux-form';

export const validateCustomerForm = (data) => {
    const errors = validate(data, {
        'company': validators.exists()('Campo requerido.'),
        'name': validators.exists()('Campo requerido.'),
        'last_name': validators.exists()('Campo requerido.'),
        'phone': validators.exists()('Campo requerido.'),
        'country': validators.exists()('Campo requerido.'),
    });


    return errors;
};
