import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled } from '../../../../../Utils/renderField/renderField';
import { months as meses } from '../../../../../Utils/months';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';


const TableRow = props => {
    const { system, index, fields, dollar_exchange } = props;

    const months = meses
    const calculateTotalForRow = (isDollar) => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {months.map((month, monthIndex) => (
                    <td key={month} style={{ width: "6.9%" }} className="custom-cell text-center">
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={renderFieldDisabled}
                            isTextarea={false}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
                <td style={{ width: "6.9%" }} className="custom-cell text-center">
                    {new Intl.NumberFormat('es-GT', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculateTotalForRow())}
                </td>
            </tr>
        </React.Fragment>
    );
};


const GenericTable = props => {
    const { fields, item, values_kg } = props;
    const dollar_exchange = item
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    {columnas.map(columna => columna)}
                                    <th style={{ width: "6.9%" }}>TOTAL</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    dollar_exchange={dollar_exchange}
                                    kg_proyectados={values_kg}

                                />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const KgProjected = props => {
    return (
        <div>
            <FieldArray
                name='kg_proyectados.generic_row'
                component={GenericTable}
                props={props}
            />
        </div>
    );
}

const formName = 'FincaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'table') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(KgProjected);