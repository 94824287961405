import React, { useState, useEffect } from 'react';
import CardForm from '../../../../Utils/Cards/CardForm';
import { Field, reduxForm, FieldArray } from 'redux-form';
import CardPunteado from "../../../../Utils/Cards/CardPunteado";
import AdministrativosTable from "./AdministrativoTable";
import { renderField, renderSwitch, renderFieldDisabled } from "../../../../Utils/renderField/renderField";
import { Link } from 'react-router-dom';
import CategoriaModal from "./Modal/CategoriaModal";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');
import validate from './validate';

import { connect } from 'react-redux';

const renderTable = ({ fields, dollar_exchange, change }) => (
    <div>
        {fields.map((row, index) => (
            <div key={index} className='col-sm-12 p-0'>
                <AdministrativosTable fieldName={`${row}.generic_row`} dollar_exchange={dollar_exchange} change={change} />
            </div>
        ))}
    </div>
);

const renderCategory = ({ fields, dollar_exchange, openModal, closeModal, open, change }) => {

    const delete_category = (index) => {
        let message = '¿Estás seguro de confirmar todos los cambios?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary btn-modal',
                cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
                content: '',
                title: 'darck',
                popup: 'custom-modal-size',
            },
            buttonsStyling: false,
            showCloseButton: true,
            focusConfirm: false,
            focusCancel: false,
            padding: "1em",
        });

        SwalMod.fire({
            title: 'ADVERTENCIA',
            html: 'Si eliminas la categoría perderás toda la información, <br/>' + message,
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                fields.remove(index);
            }
        });
    }

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (value) => {
        setName(value);
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    const addCategory = () => {
        if ([name, description].includes('')) {
            setMessage('Todos los campos son obligatorios');
            setTimeout(() => {
                setMessage('');
            }, 3000);
            return;
        }
        closeModal();
        fields.push({ name: name, description: description, category_table: [{}] });
    }
    return (
        <div>
            <CategoriaModal open={open} closeModal={closeModal} handleNameChange={handleNameChange} handleDescriptionChange={handleDescriptionChange} addCategory={addCategory} message={message} />
            {fields.map((table, index) => (
                <div key={index} className='col-sm-12 p-0'>
                    <div className="mt-2">
                        <Field
                            name={`${table}.name`}
                            component={renderFieldDisabled}
                            width="50%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                        <Field
                            name={`${table}.description`}
                            component={renderField}
                            style={{ display: 'none' }}
                            width="50%"
                        />
                        <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end mb-2 eliminar-tab-generica" onClick={() => delete_category(index)}>
                            ELIMINAR RUBRO
                        </div>
                    </div>
                    <FieldArray name={`${table}.category_table`} dollar_exchange={dollar_exchange} change={change} component={renderTable} />
                </div>
            ))}
            <div className='mb-4'>
                <CardPunteado>
                    <div className='p-4 mb-4'>
                        <button type="button" className="btn btn-categories " onClick={(e) => { e.preventDefault(); openModal(); }}>
                            + AGREGAR RUBRO
                        </button>
                    </div>
                </CardPunteado>
            </div>
        </div>
    );
}

let AdministrativosForm = props => {
    const { handleSubmit, editar, item, openModal, closeModal, open, initialValues, change } = props;

    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (initialValues && initialValues.is_plant) {
            setSelectedOption('plant');
        } else if (initialValues && initialValues.is_logistics) {
            setSelectedOption('logistics');
        }
    }, [initialValues]);

    const handleToggle = (option) => {
        if (selectedOption === option) {
            setSelectedOption(null);
            change(option === 'plant' ? 'is_plant' : 'is_logistics', false);
        } else {
            setSelectedOption(option);
            change(option === 'plant' ? 'is_plant' : 'is_logistics', true);
            change(option === 'plant' ? 'is_logistics' : 'is_plant', false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO ADMINISTRATIVO"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >
                    <div className="col-12 p-0 mt-2">
                        <div className='d-flex flex-wrap'>
                            <div className='mr-4 w-25'>
                                <label htmlFor="name" className="m-0">Nombre del presupuesto</label>
                                <div className="form-group">
                                    <Field
                                        id='name'
                                        name="name"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="is_plant" className="m-0">¿Aplicable para el control de calidad de plantas?</label>
                                <div className="form-group">
                                    <Field
                                        id='is_plant'
                                        name="is_plant"
                                        component={renderSwitch}
                                        type="text"
                                        className="form-control"
                                        checked={selectedOption === 'plant'}
                                        onChange={() => handleToggle('plant')}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="is_logistics" className="m-0">¿Aplicable a logística y exportación?</label>
                                <div className="form-group">
                                    <Field
                                        id='is_logistics'
                                        name="is_logistics"
                                        component={renderSwitch}
                                        type="text"
                                        className="form-control"
                                        checked={selectedOption === 'logistics'}
                                        onChange={() => handleToggle('logistics')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FieldArray name="category_budget" dollar_exchange={item} openModal={openModal} closeModal={closeModal} open={open} component={renderCategory} change={props.change} />
                </CardForm>
                <div className='buttons-box mt-5'>
                    <Link className="btn btn-outline-dark mr-3 p-2 btn-modal" type="button" to="/administrativos/configuration">REGRESAR</Link>
                    <button type="submit" className="btn btn-primary m-4 btn-modal">
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    initialValues: (state.form && state.form.AdministrativosForm && state.form.AdministrativosForm.values) || {},
});

AdministrativosForm = connect(mapStateToProps)(
    reduxForm({
        form: 'AdministrativosForm',
        validate,
        enableReinitialize: true,
    })(AdministrativosForm)
);

export default AdministrativosForm;
