import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import List from "./SalesList"
import ListSubProduction from './SalesSubProduction'
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import { Link } from 'react-router-dom';
import ProduccionExportable from '../../configuration/List/Table/ProductionExportableLatex'
import SalesDivers from './SalesDivers';

export default class SalesListShow extends Component {
    state = {
        sublista: false,
    }

    componentDidMount() {
        this.setState({ mounted: true });
        this.cargandoData();
    }

    componentDidUpdate(prevProps) {
        if (this.state.mounted && this.props.match.params.id !== prevProps.match.params.id) {
            this.cargandoData();
        }
    }

    cargandoData() {
        const { match, sub_listar, listar_resumen, page } = this.props;

        if (match.params.id) {
            sub_listar(match.params.id);
            this.setState({ sublista: true });
        } else {
            listar_resumen(page);
            this.setState({ sublista: false });
        }
    }

    render() {
        const { setTab, tab } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card noShadow icon="Side_activos/ventas.svg" titulo="PRESUPUESTO DE VENTAS" subtitulo="RESUMEN" noBorder={this.state.sublista ? false : true}>
                    {this.state.sublista ? (
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6">
                                <h4 className="mr-2">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                            </div>
                        </div>
                    ) : (
                        <Tabs activeKey={tab} onChange={tab => setTab(tab)}>
                            <TabPane tab="EN CURSO" key="EN_CURSO">
                                <CardEmpty noShadow>
                                    <div className="row col-md-12 p-0 mt-2 mb-4">
                                        <div className="col-md-6">
                                            <h4 className="mr-2 titulo_principal">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <List {...this.props} />
                                    </div>
                                </CardEmpty>
                                <br/>
                                <CardEmpty noShadow >
                                    <div className="col-md-3 p-0 mt-2">
                                        <div className="md-12">
                                            <h6 className="mb-4"><strong>VENTAS VARIAS</strong></h6>
                                        </div>
                                    </div>
                                    <SalesDivers
                                        {...this.props}
                                    />
                                </CardEmpty>
                                <br/>
                                <CardEmpty noShadow >
                                    <div className="col-md-3 p-0 mt-2">
                                        <div className="md-12">
                                            <h6 className="mb-4"><strong>PRODUCCIÓN EXPORTABLE</strong></h6>
                                        </div>
                                    </div>
                                    <ProduccionExportable
                                        {...this.props}
                                    />
                                </CardEmpty>
                            </TabPane>
                            <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                                {/* <CardEmpty noShadow>
                                    <div className="row col-md-12 p-0 mt-2 mb-4">
                                        <div className="col-md-6">
                                            <h4 className="mr-2 titulo_principal">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <List {...this.props} />
                                    </div>
                                </CardEmpty> */}
                            </TabPane>
                        </Tabs>
                    )}
                    {this.state.sublista && <ListSubProduction {...this.props} />}
                    {this.state.sublista && (
                        <div className='buttons-box'>
                            <Link className="btn btn-outline-dark m-4 btn-modal" type="button" to="/sales/summary">REGRESAR</Link>
                        </div>
                    )}
                </Card>
            </div>
        );
    }

}