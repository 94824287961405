import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { months } from '../../../../Utils/months';
import ver_icon from 'assets/img/icons/ver.svg'
import { useDispatch } from 'react-redux';

const ListadoCosto = (props) => {
    const { data, loader, onSortChange, openModal, readShow } = props;

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };


    const formatDollar = (cell, row) => {
        if (cell && cell !== '--') {
            const formattedValue = parseFloat(cell).toFixed(7);
            return `$ ${formattedValue}`;
        }
        return '--';
    };


    return (
        <div>
            <Grid
                hover
                striped
                data={data.total_costs || []}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                >
                    Costos de producción por kg / tipo de producto
                </TableHeaderColumn>
                <TableHeaderColumn
                        dataField="FA"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        FA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="LA"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        LA
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="NF"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        NF
                    </TableHeaderColumn>
            </Grid>
            <br />
        </div>
    );
};

export default ListadoCosto;
