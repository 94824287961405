import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../../Utils/Grid/StandardActions";
import Grid from "../../../../../Utils/Grid";
import { months } from '../../../../../Utils/months';
import GridEmpty from '../../../../../Utils/Grid/GridEmpty';
import { Link } from 'react-router-dom';

const List = props => {
    const { data, loader, onSortChange } = props;
    const dataExportable = Array.isArray(data.production_exportable) ? data.production_exportable : [];
    const formatearNumero = (dato) => {
        if (dato === null || dato === undefined || isNaN(dato)) {
            return '';
        }

        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero) + ' kg';
    }
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales';
        }
        return '';
    };

    const meses = months
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <GridEmpty
            hover
            striped
            data={dataExportable}
            loading={loader}
            onSortChange={onSortChange}
            pagination={false}
            className="pb-3"
            >
            
            <TableHeaderColumn
                dataField="name"
                isKey={true}
                dataSort
                columnClassName={columnaClaseTotal}
                width='130'
            >
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn
                dataField="total"
                dataSort
                columnClassName={columnaClaseTotal}
                dataFormat={formatearNumero}
                width='160'
            >
                Total
            </TableHeaderColumn>
        </GridEmpty>
    );
};
export default List;