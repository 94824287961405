import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import ComportamientoCreditoFiscal from './ComportamientoCreditoFiscal'
import IvaCredit from './IvaCredit'
import TablaPrecioCompra from './PrescioCompra'
import PrecioVenta from './PrecioVenta'
import ProduccionFinca from './ProduccionFinca'
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/warning.png');
import validate from "./validate"
import { connect } from 'react-redux';

export let ParametrosGenerales = props => {
  const { handleSubmit, data, updateParameters } = props;

  const submitHandle = (formData) => {
    update_parameters(formData)

  }
  const update_parameters = (formData) => {
    const SwalMod = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary btn-modal',
        cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
        content: '',
        title: 'darck',
        popup: 'custom-modal-size',
      },
      buttonsStyling: false,
      showCloseButton: true,
      focusConfirm: false,
      focusCancel: false,
      padding: "1em",
    });

    SwalMod.fire({
      title: 'CONFIRMACIÓN',
      text: '¿Estás seguro de confirmar todos los cambios?',
      imageUrl: alerta,
      imageAlt: 'Alerta',
      showCancelButton: true,
      confirmButtonText: 'GUARDAR',
      cancelButtonText: 'CANCELAR',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateParameters(data.id, formData)
      }
    });
  }

  return (
    <form onSubmit={handleSubmit(submitHandle)}>
      <div className="mb-4 col-12">
        <ProduccionFinca {...props} />
        <br />
        <PrecioVenta {...props} />
        <br />
        <TablaPrecioCompra {...props} />
        <br />
        <ComportamientoCreditoFiscal {...props} />
        <br />
        <IvaCredit {...props} />


        <div className='buttons-box mt-5'>
          <button type="submit" className="btn btn-primary btn-flotante">
            ACTUALIZAR
          </button>
        </div>
        <br />
        <br />
        <br />
      </div>
    </form>
  );
};

const formName = 'ParametrosGenerales';
const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
  const parameterTaxCredit = selector(state, 'parameter_tax_credit') || {}; 
  
  return {
    parameterTaxCredit, 
  };
};

ParametrosGenerales = reduxForm({
  form: formName,
  validate,
})(ParametrosGenerales);

ParametrosGenerales = connect(mapStateToProps)(ParametrosGenerales);

export default ParametrosGenerales;