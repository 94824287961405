import React, {Component} from 'react'
import Grid from "../../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "../../../../Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { formatearKG } from '../../../../Utils/renderField/renderReadField';

export default class TransportList extends Component{
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        const { data, loader, page } = this.props; //Constantes
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo.png"
                    titulo="TIPO DE TRANSPORTE"
                    subtitulo="Entre Ríos">
                    <Header to="transport/crear" textBtn="CREAR TRANSPORTE" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                    page={page}>
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "transport", eliminar})}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="name"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="description"
                            dataSort
                        >
                            DESCRIPCIÓN
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
