import React, { useState, useEffect } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled, renderCurrency, renderQuetzalesDisabled, renderFieldCheck } from '../../../../../Utils/renderField/renderField';
import { renderField } from "../../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const TableRow = ({ system, index, fields, change, dollar_exchange, step }) => {
    const [currency, setCurrency] = useState('QUETZALES');
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];
    const isNew = fields.get(index).isNew;

    const handleCurrencySelection = (selectedCurrency) => {
        const isDollarSelected = selectedCurrency === 'USD';
        setCurrency(selectedCurrency);
        change(`${system}.is_dollar`, isDollarSelected);
    };
    const calculateTotalForRow = (isDollar) => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={
                            step === 1
                                ?
                                isNew ? renderField : renderFieldDisabled
                                :
                                renderFieldDisabled
                        }
                        isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td style={{ width: "6.5%" }} className="custom-cell text-center">

                    {
                        fields.get(index).is_dollar
                            ?
                            new Intl.NumberFormat('es-US',
                                { style: 'currency', currency: 'USD' }).format(
                                    calculateTotalForRow())
                            :
                            new Intl.NumberFormat('es-GT',
                                { style: 'currency', currency: 'GTQ' }).format(
                                    calculateTotalForRow())
                    }

                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.5%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={
                                step === 1
                                    ? fields.get(index).is_dollar
                                        ? renderDollar : renderCurrency
                                    : fields.get(index).is_dollar
                                        ? renderDollarDisabled : renderQuetzalesDisabled
                            }
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />
                    </td>
                ))}
                <td className='sin-borde-top' style={{ width: '6.5%' }}>
                    <Field
                        name={`${system}.is_iva_credit`}
                        component={renderFieldCheck}
                        className='form-control'
                    />
                </td>
                {step === 1 &&
                    <td className="text-center sin-borde-top" style={{ width: "10%", position: 'relative' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: '5px' }}>
                                <UncontrolledDropdown>
                                    <DropdownToggle tag="span" className="action_img" data-toggle="dropdown" aria-expanded={false}>
                                        <img src={require('assets/img/icons/donate-coin.svg')} alt="Opciones" title="Opciones" />
                                    </DropdownToggle>
                                    <DropdownMenu container="body" style={{ width: "177px" }} >
                                        <div className="br-top"></div>
                                        <DropdownItem onClick={() => handleCurrencySelection('USD')}>USD</DropdownItem>
                                        <DropdownItem onClick={() => handleCurrencySelection('QUETZALES')}>QUETZALES</DropdownItem>
                                    </DropdownMenu>

                                    <div className="br-top"></div>
                                </UncontrolledDropdown>
                            </div>
                            <div>
                                <img src={require('assets/img/icons/Accion-Eliminar.svg')} alt="Borrar"
                                    title="Borrar" className="action_img"
                                    onClick={() => {
                                        fields.remove(index)
                                    }}
                                />
                            </div>
                        </div>
                    </td>
                }
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, dollar_exchange, change, step }) => {
    const months = ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"];

    const calculateTotalForMonth = (month, isDollar) => {
        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    <th style={{ width: "6.5%" }}>TOTAL</th>
                                    <th style={{ width: "6.5%" }}>1</th>
                                    <th style={{ width: "6.5%" }}>2</th>
                                    <th style={{ width: "6.5%" }}>3</th>
                                    <th style={{ width: "6.5%" }}>4</th>
                                    <th style={{ width: "6.5%" }}>5</th>
                                    <th style={{ width: "6.5%" }}>6</th>
                                    <th style={{ width: "6.5%" }}>7</th>
                                    <th style={{ width: "6.5%" }}>8</th>
                                    <th style={{ width: "6.5%" }}>9</th>
                                    <th style={{ width: "6.5%" }}>10</th>
                                    <th style={{ width: "6.5%" }}>11</th>
                                    <th style={{ width: "6.5%" }}>12</th>
                                    <th style={{ width: "6.%" }}>¿Genera IVA?</th>
                                    {step === 1 &&
                                        <th style={{ width: "6.5%" }}>ACCIONES</th>
                                    }

                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow
                                    key={index}
                                    step={step}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    change={change}
                                    dollar_exchange={dollar_exchange}
                                />)}

                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES Q</td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                    {step === 1 &&
                                        <td></td>
                                    }
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center">TOTALES $</td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                    {step === 1 &&
                                        <td></td>
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {step === 1 &&
                <div className="mt-2">
                    <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={() => fields.push({ isNew: true })}>
                        AGREGAR
                    </div>
                </div>
            }
        </div>
    );
}

const ShoppingTable = ({ fieldName, dollar_exchange, allValues, change, step }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                dollar_exchange={dollar_exchange}
                component={GenericTable}
                allValues={allValues}
                change={change}
                step={step}
            />
        </div>
    );
}

const formName = 'ShoppingForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'category_budget') || [];
    const allValues = categoryBudget.map(item => item);
    return { allValues };

};

export default connect(mapStateToProps)(ShoppingTable);