import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { standardActions } from '../../../../Utils/Grid/StandardActions';
import { months } from '../../../../Utils/months';

const InvestmentsInProgressList = ({ data, loader, onSortChange, dollar_exchange = 0, show }) => {
    if (!data || !Array.isArray(data.results)) {
        if (loader) {
            return <div>Cargando datos...</div>;
        }
        return <div>No hay datos disponibles para mostrar.</div>;
    }

    // Función para formatear número a formato de moneda
    const formatearNumero = (cell) => {
        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            style: 'currency',
            currency: 'GTQ',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatoMoneda.format(cell);
    };
    
    const formatearDolares = (cell) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return formatoMoneda.format(cell);
    };

    const formatearMoneda = (cell, row) => {
        if (row.id === 'totalesQ') {
            return formatearNumero(cell);
        }
        return formatearDolares(cell);
    };

    const columnaClaseTotal = (cell, row) => {
        if (row.id === 'totalesQ' || row.id === 'totalesDollars') {
            return 'celda-totales';
        }
        return '';
    };

    const totalesQuetzales = months.reduce((acc, mes) => {
        acc[mes] = data.results.reduce((sum, row) => sum + parseFloat(row[mes]*dollar_exchange || 0), 0);
        return acc;
    }, {});


    const totalesDolares = months.reduce((acc, mes) => {
        acc[mes] = totalesQuetzales[mes] / dollar_exchange;
        return acc;
    }, {});


    const totalAnualQuetzales = Object.values(totalesQuetzales).reduce((sum, value) => sum + value, 0);
    const totalAnualDolares = totalAnualQuetzales / dollar_exchange;

    const dataConTotales = [
        ...data.results,
        { ...totalesQuetzales, name: 'TOTALES Q', id: 'totalesQ', total: totalAnualQuetzales },
        { ...totalesDolares, name: 'TOTALES $', id: 'totalesDollars', total: totalAnualDolares }
    ];
    const columnaAcciones = (cell, row) => {
        if (row.id === 'totalesQ' || row.id === 'totalesDollars') {
            return '';
        }
        return standardActions({ ver_administrativo: "summary", show })(cell, row);
    };
    return (
        <BootstrapTable
            data={dataConTotales}
            striped
            hover
            condensed
            loading={loader}
            options={{ onSortChange }}
            
        >
            <TableHeaderColumn
                dataField='name'
                isKey
                dataSort
                columnClassName={columnaClaseTotal}
                width='180'
                >
                PRESUPUESTO
            </TableHeaderColumn>
            {months.map((mes,index) => (
                <TableHeaderColumn
                    key={mes}
                    dataField={mes}
                    columnClassName={columnaClaseTotal}
                    dataSort
                    dataFormat={formatearMoneda}
                    width='130'
                    
                >
                    {index + 1}
                </TableHeaderColumn>
            ))}
            <TableHeaderColumn
                    dataField='total'
                    columnClassName={columnaClaseTotal}
                    dataSort
                    dataFormat={formatearMoneda}
                    width='150'
            >
                TOTAL
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                width='100'
                dataSort
                columnClassName={columnaClaseTotal}
                dataFormat={columnaAcciones}
            >
                ACCIONES
            </TableHeaderColumn>
        </BootstrapTable>
    );
};

export default InvestmentsInProgressList;
