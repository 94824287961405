import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/tax_credit/tax_credit';
import TaxCreditListSummary from "./TaxCredit";


const ms2p = (state) => {
  return {
    ...state.tax_credit,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(TaxCreditListSummary);
