import React from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import { Field } from 'redux-form';
import { renderDollar, renderNumberPercentage, renderDollarDisabled } from '../../Utils/renderField/renderField';
import { FormatoDollar, FormatoPorc } from '../../Utils/renderField/renderTableField';



const TablePurchasePriceObjetive = data => {
    return (
        <div className='container'>
            <div className='table-responsive'>
                <table
                    className="table table-striped"
                >
                    <thead className='head-table'>
                        <tr>
                            <th scope="col">PRODUCTO</th>
                            <th scope="col">BOLSA</th>
                            <th scope="col">DIFERENCIAL</th>
                            <th scope="col">PRECIO BASE</th>
                            <th scope="col">PRECIO COMPRA</th>
                        </tr>
                    </thead>
                    <tbody className='body-bable'>
                        <tr className="table-bordered border-secundary">
                            <td>
                                LATEX
                            </td>
                            <td>
                                <Field
                                    name={'product_latex_bolsa'}
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                />
                            </td>
                            <td>
                                <Field name='product_latex_differential'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_latex_base_price'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_latex_buy_price'
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                    className="form-control" />
                            </td>
                        </tr>
                        <tr className="table-bordered border-secundary">
                            <td>
                                SOLIDOS
                            </td>
                            <td>
                                <Field
                                    name={'product_solido_bolsa'}
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                />
                            </td>
                            <td>
                                <Field name='product_solido_differential'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_solido_base_price'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_solido_buy_price'
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                    className="form-control" />
                            </td>
                        </tr>
                        <tr className="table-bordered border-secundary">
                            <td>
                                SKIM
                            </td>
                            <td>
                                <Field
                                    name={'product_skim_bolsa'}
                                    type="number"
                                    placeholder='$0.00'
                                    component={renderDollar}
                                />
                            </td>
                            <td>
                                <Field name='product_skim_differential'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_skim_base_price'
                                    type="text"
                                    disabled={true}
                                    component={renderDollarDisabled}
                                    className="form-control" />
                            </td>
                            <td>
                                <Field name='product_skim_buy_price'
                                    type="number"
                                    component={renderDollar}
                                    placeholder='$0.00'
                                    className="form-control" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
const TableSalePriceObjetive = (data) => {
    return (
        <div className='tab-finca'>
            <div className='container'>
                <div
                    className="table-responsive"
                >
                    <table
                        className="table table-striped "
                    >
                        <thead className='head-table'>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">PRECIO BASE</th>
                                <th scope="col">PRECIO COMPRA</th>
                            </tr>
                        </thead>
                        <tbody className='body-bable'>
                            <tr>
                                <td>
                                    LATEX
                                </td>
                                <td>
                                    <Field name={'latex_base_price'}
                                        type="number"
                                        component={renderNumberPercentage}
                                        className="form-control" />
                                </td>
                                <td>
                                    <Field name={'latex_buy_price'}
                                        type="number"
                                        component={renderNumberPercentage}
                                        className="form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>SOLIDO</td>
                                <td>
                                    <Field name='solido_base_price'
                                        type="text"
                                        disabled={true}
                                        component={renderDollarDisabled}
                                        className="form-control" /> </td>
                                <td>
                                    <Field name='solido_buy_price'
                                        type="text"
                                        disabled={true}
                                        component={renderDollarDisabled}
                                        className="form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td>SOLIDO</td>
                                <td>
                                    <Field
                                        name='solido_base_price_two'
                                        type="text"
                                        disabled={true}
                                        component={renderDollarDisabled}
                                        className="form-control" />
                                </td>
                                <td>
                                    <Field
                                        name='solido_buy_price_two'
                                        type="text"
                                        disabled={true}
                                        component={renderDollarDisabled}
                                        className="form-control" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
const PrecioVenta = props => {

    return (
        <div>
            <CardEmpty padding={true}>
                <h2 className='titulo_parrafos'>Precio de venta, compra, márgenes y variables clave de costo</h2>
                <div className='fn-info'>
                    <div className='row  col-md-11 p-0'>
                        <div className='col-md-3 p-0'>
                            <div className='col-md-12 col-sm-12'>
                                <span className='finca-entre-rios'>MARGEN OBJETIVO PONDERADO LATEX</span>
                            </div>
                            <div className='col-md-12  form-group'>
                                <Field name="mar_obj_pon_latex" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <div className='col-md-12 col-sm-12'>
                                <span className='finca-entre-rios'>MARGEN OBJETIVO PONDERADO SOLIDOS</span>
                            </div>
                            <div className='col-md-12  form-group'>
                                <Field name="mar_obj_pon_solido" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <div className='col-md-12 col-sm-12'>
                                <span className='finca-entre-rios'>MARGEN OBJETIVO LATEX</span>
                            </div>
                            <div className='col-md-12  form-group'>
                                <Field name="mar_obj_latex" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                            </div>
                        </div>
                        <div className='col-md-3 p-0'>
                            <div className='col-md-12 col-sm-12'>
                                <span className='finca-entre-rios'>MARGEN OBJETIVO SOLIDO</span>
                            </div>
                            <div className='col-md-12  form-group'>
                                <Field name="mar_obj_solido" component={renderDollar} type="number" className="form-control" placeholder='$0.00' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='fn-info'>
                    <div className='row col-md-12 p-0'>
                        <div className='col-md-6 p-0'>
                            <TablePurchasePriceObjetive {...props} />
                        </div>
                        <div className='col-md-6 p-0'>
                            <TableSalePriceObjetive {...props} />
                        </div>
                    </div>
                </div>
            </CardEmpty>
        </div>
    );
}
export default PrecioVenta;