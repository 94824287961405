import React, { useEffect } from 'react';
import CardForm from '../../../../../Utils/Cards/CardForm';
import { Field, reduxForm, FieldArray, } from 'redux-form';
import AdministrativosEnCursoTable from "./AdministrativosEnCursoTable"
import { renderFieldDisabled } from "../../../../../Utils/renderField/renderField"
import { Link } from 'react-router-dom';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
const renderTable = ({ fields, dollar_exchange, change }) => (
    <div>
        {fields.map((row, index) => (
            <div key={index} className='col-sm-12 p-0'>
                <AdministrativosEnCursoTable fieldName={`${row}.generic_row`} dollar_exchange={dollar_exchange} change={change} />
            </div>
        ))}
    </div>
);

const renderCategory = ({ fields, dollar_exchange, change }) => {

    return (
        <div>
            {fields.map((table, index) => (
                <div key={index} className='col-sm-12 p-0'>
                    <div className="mt-2">
                        <Field
                            name={`${table}.name`}
                            component={renderFieldDisabled}
                            width="50%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                    </div>
                    <FieldArray name={`${table}.category_table`} dollar_exchange={dollar_exchange} change={change} component={renderTable} />
                </div>
            ))}
        </div>
    );
}



let AdministrativosEnCursoVer = props => {
    const { handleSubmit, item, data, loader, onSortChange } = props;
    const month = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];

    useEffect(() => {
        props.show(props.match.params.id)

    }, [])

    const totalesQuetzales = month.reduce((acc, mes) => {
        acc[mes] = data[mes]
        return acc;
    }, {});

    const totalesDolares = month.reduce((acc, mes) => {
        acc[mes] = totalesQuetzales[mes] / item;
        return acc;
    }, {});
    const totalAnualQuetzales = Object.values(totalesQuetzales).reduce((sum, value) => sum + value, 0);
    const totalAnualDolares = totalAnualQuetzales / item;
    const dataTotales = [
            { ...totalesQuetzales, name: 'TOTALES Q',id: 'totalesQ', totales: totalAnualQuetzales},
            { ...totalesDolares, name: 'TOTALES $', id: 'totalesDollars', totales: totalAnualDolares}
    ]

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            style: 'currency',
            currency: 'GTQ',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero);
    }

    const formatearDolares = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero);
    }
    const formatearMoneda = (cell, row) => {
        if (row.id === 'totalesDollars') {
            return formatearDolares(cell);
        }
        return formatearNumero(cell);
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.id === 'totalesQ' || row.id === 'totalesDollars') {
            return 'celda-totales-generales'; // Esta es la clase CSS que necesitarás definir
        }
        return '';
    };


    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO ADMINISTRATIVO"
                    subtitulo="RESUMEN"
                    subtituloForm="VER"
                    col="12"
                    noShadow
                >
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12  form-group">
                            <Field
                                name="name"
                                component={renderFieldDisabled}
                                type="text"
                                className="form-control"
                                width="50%"
                                applyStyles={false}
                                isTextarea={false}
                            />

                        </div>
                    </div>
                    <div className='mt-4 mb-4'>
                        <FieldArray
                            name="category_budget"
                            dollar_exchange={item}
                            component={renderCategory}
                        />
                    </div>
                    <br/>
                    <div className='mt-4 mb-4'>
                        <BootstrapTable 
                            hover 
                            striped 
                            data={dataTotales} 
                            loading={loader} 
                            onSortChange={onSortChange} 
                            pagination={false} 
                            headerStyle={{ display: 'none' }}
                            className="pb-3"
                        >

                            <TableHeaderColumn
                                dataField="name"
                                dataSort
                                isKey
                                columnClassName="cel-totales-generales-start"
                                width="190"
                            // dataFormat={renderCurrency}
                            />
                            {month.map((mes, index) => (
                                <TableHeaderColumn
                                    key={mes}
                                    dataField={mes}
                                    columnClassName={columnaClaseTotal}
                                    dataSort
                                    dataFormat={formatearMoneda}
                                    width="140"

                                />
                            ))}
                            <TableHeaderColumn
                                dataField='totales'
                                dataSort
                                columnClassName="cel-totales-generales-end"
                                dataFormat={formatearMoneda}
                                width='150'
                            />
                        </BootstrapTable>
                    </div>

                </CardForm>
                <div className='buttons-box mt-5'>
                    <Link className="btn btn-outline-dark mr-3 p-2 btn-modal m-5" type="button" to="/administrativos/summary">REGRESAR</Link>
                </div>
            </div>
        </form>
    )
};
AdministrativosEnCursoVer = reduxForm({
    form: 'AdministrativosEnCursoVer',
})(AdministrativosEnCursoVer);

export default AdministrativosEnCursoVer;