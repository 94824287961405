import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "packings",
    "packing",
    "EditarPackingForm",
    "/packings",
);

export default handleActions(reducers, initialState);
