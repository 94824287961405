import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { months } from '../../../../Utils/months';
import editar_img from 'assets/img/icons/editar.svg';
import { standardActions } from "../../../../Utils/Grid/StandardActions";

const Listado = (props) => {
    const { data, eliminar, loader, onSortChange, openModal, readEdit, initializeEmptyForm,setInitialValues } = props;

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };

    const meses = months;

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));
    const handleAgregar = () => {
        initializeEmptyForm('CategoriaModal');
        openModal();
    };

    const abrirModalYConsultar = (id) => {
        readEdit(id);
        openModal();
    };

    return (
        <div>
            <Grid
                hover
                striped
                data={!data || !Array.isArray(data.results) ? [] : data }
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
                
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    width='180'
                >
                    PRESUPUESTO
                </TableHeaderColumn>
                {columnas}
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width='100'
                    dataSort
                    dataFormat={(cell, row) => (
                        <div className="d-flex justify-content-center">
                            <button
                                className=""
                                onClick={() => abrirModalYConsultar(cell)}
                                style={{ border: 'none', background: 'none' }}
                            >
                                <img src={editar_img} alt="Editar" className="action_img" />
                            </button>
                            <div>
                                {standardActions({ eliminar })(cell, row)}
                            </div>
                        </div>
                    )}
                >
                    ACCIONES
                </TableHeaderColumn>
            </Grid>
            <div className="mt-2">
                <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={handleAgregar}>
                    AGREGAR
                </div>
            </div>
            <br />
        </div>
    );
};

export default Listado;
