import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validatorFromFunction, validators, combine } from 'validate-redux-form';
import {renderField, renderFilePicker, SelectField, renderNumber} from '../../Utils/renderField/renderField';
import {Link} from "react-router-dom";

const genders = [
    {"label": "Masculino", "value": 0},
    {"label": "Femenino", "value": 1},
];

const ProfileForm = (props) => {
    const { handleSubmit, me, setAvatar } = props;
    return (
            <form action="" onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    <div className="row  col-12 p-0">
                        <div className="col-12 col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="username" className="m-0">NOMBRE DE USUARIO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="username" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="nombre" className="m-0">NOMBRE COMPLETO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="nombre" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="email" className="m-0">E-MAIL</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="email" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="new_password" className="m-0">CONTRASEÑA (Nueva)</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="new_password" component={renderField} type="password" className="form-control" />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="new_password" className="m-0">FOTO DE PERFIL</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field photo={me && me.avatar ? me.avatar : null} setFile={setAvatar} name="avatar" component={renderFilePicker} />
                            </div>
                        </div>
                    </div>
                    <div className="buttons-box mt-5">
                        <Link className="btn btn-outline-dark mr-5" to="/">CANCELAR</Link>
                        <button type="submit" className="btn btn-primary">GUARDAR</button>
                    </div>
                </div>
            </form>
        );
};

export default reduxForm({
    form: 'profile', // a unique identifier for this form
})(ProfileForm);
