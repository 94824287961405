import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import Listado from './Listado2';
import SummaryModal from './Modal/CategoriaModal'
export default class DebtManagementSummary extends Component {
    state = {
        open: false,
    };
    componentDidMount() {
        const { getDeptManagementSummary } = this.props;
        getDeptManagementSummary();
    }
    openModalCategory = () => {
        this.setState({ open: true });
    };
    closeModalCategory = () => {
        this.setState({ open: false });
    };

    render() {
        const { setTab, tab, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card noShadow
                    icon="Side_activos/deuda.svg"
                    titulo="PRESUPUESTO MANEJO DE DEUDA"
                    subtitulo="RESUMEN"
                    noBorder
                >
                    <Tabs
                        activeKey={tab}
                        onChange={tab => { setTab(tab) }}
                    >
                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mt-2 mb-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">PRESUPUESTO MANEJO DE DEUDA</h4>
                                    </div>
                                </div>
                                <div className='mb-4'>
                
                                    <Listado
                                    open={this.state.open}
                                    closeModal={this.closeModalCategory}
                                    openModal = {this.openModalCategory} 
                                    {...this.props} />
                                </div>
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                            <CardEmpty noShadow>
                                {/* <DeptManagementEnCurso {...this.props} dollar_exchange={item} /> */}
                            </CardEmpty>
                        </TabPane>
                    </Tabs>

                    <SummaryModal
                        open={this.state.open}
                        closeModal={this.closeModalCategory}
                        {...this.props}
                    />

                </Card>
            </div>
        )
    }
}