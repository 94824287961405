import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './UsuarioForm';
import { UsuarioUpdateForm } from './UsuarioForm';
import {getMe} from "../../../../../redux/modules/cuenta/login";

export default class UsuarioCrear extends Component {
  state = {
      editar: false,
      mensaje: 'Agregar usuario',
      password: false,
  };
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.leer(this.props.match.params.id);
          this.setState({
                editar: true,
            });
      }
  }
  actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }
  render() {
    const { crear, update } = this.props;
    const { updateData, loader } = this.props;
    return (
      <div className="mb-4 col-12">
        <CardForm
          icon="configuraciones_activo.png"
          titulo="ADMINISTRACIÓN"
          subtitulo="USUARIO"
          subtituloForm = {this.state.editar ? "Editar" : "Nuevo"}
          col="11">
          <LoadMask loading={loader} blur>
            {
              this.state.editar ?
                <UsuarioUpdateForm
                  mostrar_pass={this.state.password}
                  onSubmit={this.actualizar}
                  updateData={updateData}
                  editando={true}
                  permisos={this.getPermissions}/>
                :
                <Form onSubmit={crear}/>
            }
          </LoadMask>
        </CardForm>
      </div>
    )
  }
}
