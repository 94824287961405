import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderDollar, renderField, renderSwitch, SelectField } from '../../../../Utils/renderField/renderField';
import { Country } from 'country-state-city';
import CustomerCommissionsSolidos from './CommissionFormsSolidos';
import customerCommissionsLatex from './CommissionFormsLatex';
import {validateCustomerForm} from './validate';
let CustomerForm = props => {

    const loadCountries = () => {
        const countries = Country.getAllCountries();
        return countries.map(country => ({ value: country.name, label: country.name }));
    };
    const [countriesOptions, setCountriesOptions] = useState([]);

    useEffect(() => {
        setCountriesOptions(loadCountries());
    }, []);

    const { handleSubmit, isCommissionsActive } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="company" className="m-0">NOMBRE DE EMPRESA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="company" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="name" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="name" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="last_name" className="m-0">APELLIDO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="last_name" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="credit_days" className="m-0">DíAS DE CRÉDITO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="credit_days" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="phone" className="m-0">TELÉFONO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="phone" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="identification_number" className="m-0">N° IDENTIFICACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="identification_number" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="row col-12 p-0">
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">CORREO ELECTRONICO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="country" className="m-0">PAIS</label>
                        </div>

                        <div className="col-md-12  form-group">
                            <Field
                                name='country'
                                options={countriesOptions}
                                component={SelectField}
                                className='form-control'
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    <div className='col-md-6 p-0 '>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="is_commissions" className="m-0">¿Aplica a comisión?</label>
                        </div>

                        <div className="col-md-12 m-4 form-group">
                            <Field
                                id='is_commissions'
                                name="is_commissions"
                                component={renderSwitch}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    {isCommissionsActive && (
                        <div className="row col-12 p-0">
                            <div className="col-md-6 p-0 ">
                                <div className="col-md-12 col-sm-12 text-center">
                                    <label htmlFor="commissions_solidos" className="m-0">COMISIONES SOLIDOS </label>
                                </div>
                                <br />
                                <div className='col-md-12 text-center'>
                                    <FieldArray
                                        name="commissions_solidos"
                                        component={CustomerCommissionsSolidos}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0 ">
                                <div className="col-md-12 col-sm-12 text-center">
                                    <label htmlFor="commissions_latex" className="m-0">COMISIONES LATEX </label>
                                </div>
                                <br />
                                <div className='col-md-12 text-center'>
                                    <FieldArray
                                        name="commissions_latex"
                                        component={customerCommissionsLatex}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/customers">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    );
};



// Seleccionar valores del formulario desde el estado de Redux
const selector = formValueSelector('EditarCustomerForm');

const mapStateToProps = (state) => ({
    isCommissionsActive: selector(state, 'is_commissions') || false,

});

CustomerForm = connect(mapStateToProps)(reduxForm({
    form: 'EditarCustomerForm',
    validate: validateCustomerForm,
    enableReinitialize: true,
})(CustomerForm));

export const CustomerUpdateForm = reduxForm({
    form: 'EditarCustomerForm',
    validate: validateCustomerForm,
    asyncBlurFields: []
})(CustomerForm);

export default CustomerForm;