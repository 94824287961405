import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');   

let url = 'debt-management'
let returnUrlConfig = '/deuda/configuration'
let returnUrlSummary = '/deuda/summary'
// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER';
const DATA = 'DATA';
const ITEM_DATA = 'ITEM';
const TAB_EN_CURSO = 'TAB_EN_CURSO';
const TAB_PROYECCIONES_ANUALES = 'TAB_PROYECCIONES_ANUALES';
const PAGE_EN_CURSO = 'PAGE_EN_CURSO';
const PAGE_ANUALES = 'PAGE_ANUALES';
const DATA_MODAL = 'DATA_MODAL';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});
const setDataModal = data_modal => ({
    type: DATA_MODAL,
    data_modal,
});


const confirm_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(push(returnUrlConfig));
        }
        dispatch(push(returnUrlConfig));
    });
}

// -----------------------------------
// Actions
// -----------------------------------


const initializeEmptyForm = () => (dispatch) => {
    dispatch(initializeForm('DebtManagementForm', {}));
    dispatch(setDataModal({}));

};
const initializeFormPrincipal = (response) => (dispatch) => {
    dispatch(setData(response));
    dispatch(initializeForm('DebtManagementForm', response.results));

};

const show = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_debt_detail`,params).then((response) => {
        dispatch(setDataModal(response));
        dispatch(initializeForm('ModalSummary', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDeptManagementSummary = () => ( dispatch ) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_debts`).then((response) => {
        dispatch(setData(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const getDebtConfig = () => ( dispatch ) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_debts_for_config`).then((response) => {
        dispatch(setData(response));
        dispatch(initializeForm('DebtManagementForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id,data) => (dispatch) => {
        dispatch(setLoader(true));
    api.put(`${url}/${id}`,data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push(returnUrlSummary));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editarModal = (id,data) => (dispatch) => {
    dispatch(setLoader(true));
api.put(`${url}/${id}`,data).then(() => {
    NotificationManager.success('Registro actualizado', 'Éxito', 3000);
    dispatch(getDebtConfig());
    dispatch(push(returnUrlConfig));
}).catch(() => {
    NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
}).finally(() => {
    dispatch(setLoader(false));
});
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`,data).then(() => {
        confirm_save(dispatch);
        dispatch(getDebtConfig());
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la creación', 'ERROR', 3000);
        console.log(error,'Error')
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const readEdit = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_debt_detail_edit`, params)
        .then((response) => {
            const { results } = response;
            dispatch(initializeForm('DebtManagementForm', results));
            dispatch(setDataModal(response));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error al obtener datos de edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(getDeptManagementSummary());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getDeptManagementSummary())
    } else {
        dispatch(getDeptManagementSummary())
    }
};

export const actions = {
    getDeptManagementSummary,
    getDebtConfig,
    editar,
    crear,
    eliminar,
    setTab,
    show,
    readEdit,
    initializeEmptyForm,
    initializeFormPrincipal,
    editarModal
    
};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },

    [PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [TAB_EN_CURSO]: (state, { tab_encurso }) => {
        return {
            ...state,
            tab_encurso,
        };
    },
    [TAB_PROYECCIONES_ANUALES]: (state, { tab_anuales }) => {
        return {
            ...state,
            tab_anuales,
        };
    },
    [DATA_MODAL]: (state, { data_modal }) => {
        return {
            ...state,
            data_modal,
        };
    },
    
};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page_en_curso: 1,
    page_anuales: 1,
    item: {},
    tab: 'EN_CURSO',
    data_modal:{},
    
};
export default handleActions(reducers, initialState)