import { createAction, handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import { NotificationManager } from "react-notifications";
import moment from 'moment';

// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_COMPRA';
const DATA = 'DATA_ORDEN';
const ITEM_DATA = 'ITEM_ORDEN';


// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

// -----------------------------------
// Actions
// -----------------------------------

const getParameters = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('parameters/get_progreso').then((response) => {
        dispatch(setData(response));
        dispatch(initializeForm('ParametrosGenerales', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const updateParameters = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`parameters/${id}`, data).then((response) => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(setData(response));
        dispatch(initializeForm('ParametrosGenerales', response));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    getParameters,
    updateParameters,
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },

};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
};

export default handleActions(reducers, initialState)