import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import { Field, FieldArray, SubmissionError, reduxForm } from 'redux-form';
import { renderDollar, renderField, renderSwitch } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { api } from 'api';
import { months } from '../../../../../Utils/months';
import RecetaRow from './RecetaRow';
import { validate } from './validate';
import { PRODUCT } from '../../../../../../../utility/constants';

const TableRow = ({ system, index, fields }) => {
    const _months = months;
    const isNew = fields.get(index).isNew;

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                {_months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={renderDollar}
                            className="form-control"
                            placeholder='$ 0.00'
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields }) => {

    useEffect(() => {
        if (fields.length === 0) {
            fields.push({ isNew: true });
        }
    }, [fields]);

    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0 text-center">
                    <thead>
                        <tr>
                            {columnas.map(columna => columna)}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody style={{ backgroundColor: "#F0FFC7" }}>
                        {fields.map((system, index) => (
                            <TableRow key={index} system={system} index={index} fields={fields} />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const TableCategory = ({ fields, packingId, change, dollarExchange, isPacking }) => {
    const [currency, setCurrency] = useState('USD');

    const fetchData = (endpoint, params) => {
        return api.get(endpoint, { product_sales__product: PRODUCT["LATEX"], ...params })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const getCustomers = (search) => {
        return fetchData('sub-product-sales', {
            search
        });
    };

    const handleAddField = () => {
        const initialValues = {
            is_dollar: currency === "USD",
            packingId: packingId
        };
        fields.push(initialValues);
    };

    return (
        <div className="tab-finca">
            <div className="col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th className="text-center sin-borde-top" style={{ width: isPacking ? '25%' : '15%', }}>RUBRO</th>
                                {!isPacking && <th className="text-center sin-borde-top" style={{ width: '30%' }}>PRODUCTO</th>}
                                <th className="text-center sin-borde-top" style={{ width: '15%' }}>Medida/kg</th>
                                <th className="text-center sin-borde-top" style={{ width: '15%' }}>COSTO Q</th>
                                <th className="text-center sin-borde-top" style={{ width: '15%' }}>COSTO</th>
                                <th className="text-center sin-borde-top" style={{ width: '10%' }}>ACCIONES</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="tabla-con-borde">
                    <table className="table table-sm m-0 table-striped">
                        <tbody>
                            {fields.map((system, index) => (
                                <RecetaRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    change={change}
                                    getCustomers={getCustomers}
                                    dollarExchange={dollarExchange}
                                    isPacking={isPacking}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-2">
                    <div
                        type="button"
                        className="btn btn-light btn-lg btn-block d-flex justify-content-end"
                        onClick={handleAddField}
                    >
                        AGREGAR
                    </div>
                </div>
            </div>
        </div>
    );
};

const CategoriaModal = props => {
    const { open,
        closeModal,
        handleAddCategory,
        initialValues,
        message,
        change,
        allValues,
        dollarExchange,
        reset,
    }
        = props

    const [name, setName] = useState(initialValues.name || '');
    const [is_paking, setIsPaking] = useState(initialValues.is_paking || false);
    const [packings, setPackings] = useState(initialValues.recipe_row || []);
    const [is_manual, setIsManual] = useState(initialValues.is_manual || false);

    useEffect(() => {
        setName(initialValues.name || '');
        setIsPaking(initialValues.is_paking || false);
        setIsManual(initialValues.is_manual || false);
        setPackings(initialValues.packings || []);
        if (initialValues.is_paking) {
            handleRecipeChange(true);
        }
    }, [initialValues]);

    const fetchData = (endpoint, params) => {
        return api.get(endpoint, { params })
            .then((data) => {
                if (data) return data.results;
                return [];
            })
            .catch(() => {
                return [];
            });
    };

    const handleRecipeChange = async (event) => {
        setIsPaking(event);
        if (event) {
            setIsManual(false);
            const data = await fetchData('/packing', {});
            setPackings(data);
        } else {
            setPackings([]);
        }
    };

    const handleNameChange = (event) => {
        const value = event.target.value;
        setName(value)
    }

    const handleManualChange = (event) => {
        setIsManual(event);
        if (event) {
            setIsPaking(!event);
        }
    };
    const handleCloseModal = () => {
        closeModal();
        reset(); // Reiniciar el formulario al cerrar el modal
    };

    const handleAddCategoryInternal = (values) => {
        const errors = validate(values);
        if (errors.name) throw new SubmissionError(errors);
        const formData = {
            id: initialValues.id ? initialValues.id : undefined,
            name,
            is_iva_credit:values.is_iva_credit,
            is_paking,
            is_manual,
            ModalData: (is_paking) ? allValues.recipePacking : (is_manual) ? allValues.manual : allValues.recipe

        };
        handleAddCategory(formData, reset);
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
            center
            classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
                modal: 'customModalSizeLatex',
            }}
        >
            <form onSubmit={props.handleSubmit(handleAddCategoryInternal.bind(this))}>
                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <div className="col-md-12 p-0 mt-2 text-center">
                        <h4>AGREGAR CATEGORÍA DE COSTO</h4>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    {message && <div className="alerta error_mensaje">{message}</div>}
                </div>
                <div className="position-relative">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label htmlFor="name">Nombre</label>
                                <Field
                                    name="name"
                                    type="text"
                                    component={renderField}
                                    placeholder="Nombre"
                                    value={name}
                                    onChange={handleNameChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <span className="finca-entre-rios">¿Es receta de empaque?</span>
                                <Field
                                    name="is_paking"
                                    disabled={false}
                                    component={renderSwitch}
                                    type="checkbox"
                                    onChange={handleRecipeChange}
                                    checked={is_paking}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <span className="finca-entre-rios">¿Es llenado manual?</span>
                                <Field
                                    name="is_manual"
                                    disabled={false}
                                    component={renderSwitch}
                                    type="checkbox"
                                    onChange={handleManualChange}
                                    checked={is_manual}
                                />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <span className="finca-entre-rios">
                                    ¿Genera IVA?
                                </span>
                                <Field
                                    name="is_iva_credit"
                                    component={renderSwitch}
                                    type="text"
                                    className="form-control" />
                            </div>
                        </div>


                    </div>
                    {is_manual ?
                        <FieldArray
                            name='generic_row'
                            component={GenericTable}
                            allValues={allValues}
                            change={change}
                            initialValues={initialValues}
                        />
                        :
                        is_paking ? (
                            packings.map((recipe, index) => (
                                <div key={index}>
                                    <h4 style={{ margin: '0 0 5px 20px' }}>{`${recipe.name}`}</h4>
                                    <FieldArray
                                        key={index}
                                        name={`recipe[${recipe.id}]`}
                                        packingId={`${recipe.id}`}
                                        component={TableCategory}
                                        change={change}
                                        initialValues={initialValues}
                                        allValues={allValues}
                                        dollarExchange={dollarExchange}
                                        isPacking={true}
                                    />
                                </div>
                            ))
                        ) : (
                            <FieldArray
                                name="defaultRecipe"
                                component={TableCategory}
                                change={change}
                                allValues={allValues}
                                dollarExchange={dollarExchange}
                            />
                        )
                    }
                    <div className="buttons-box mt-4 mb-4">
                        <button
                            className="btn btn-outline-dark mr-3 btn-modal"
                            onClick={(e) => {
                                e.preventDefault();
                                handleCloseModal();
                            }}
                        >
                            CANCELAR
                        </button>
                        <button type="submit" className="btn btn-primary btn-modal">
                            CONTINUAR
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

const formName = 'CategoriaModal';
const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const defaultRecipe = selector(state, 'defaultRecipe') || [];
    const defaultRecipeAll = selector(state, 'recipe') || [];
    const genercRow = selector(state, 'generic_row') || [];


    return { allValues: { recipe: defaultRecipe, recipePacking: defaultRecipeAll, manual: genercRow } };

};

const ConnectedCategoriaModal = connect(mapStateToProps)(CategoriaModal);

export default reduxForm({
    form: formName,
    validate
})(ConnectedCategoriaModal);
