import React from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { AsyncSelectField, renderField, renderNumberKG } from '../../../../Utils/renderField/renderField';
import {api} from "api";

const getTransports = (search) => {
    return api.get("transport", {search}).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let PackingForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex justify-content-center">
                    <br />
                    <br />
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="name" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="name" component={renderField} type="text" className="form-control" placeholder='Nombre' />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="storage_capacity" className="m-0">CAPACIDAD DE ALMACENAMIENTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="storage_capacity" component={renderNumberKG} type="number" placeholder='0.00 Kg' className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="rol" className="m-0">TIPO DE TRANSPORTE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="type_of_transport"
                                labelKey="name"
                                label="name"
                                component={AsyncSelectField}
                                loadOptions={getTransports}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/packings">CANCELAR</Link>

                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
PackingForm = reduxForm({
    form: 'EditarPackingForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'storage_capacity': validators.exists()('Campo requerido.'),
            'type_of_transport': validators.exists()('Campo requerido.'),

        })
    }
})(PackingForm);
export const PackingUpdateForm = reduxForm({
    form: 'EditarPackingForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'storage_capacity': validators.exists()('Campo requerido.'),
            'type_of_transport': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(PackingForm);

export default PackingForm
