import React, { useState, useEffect } from "react";
import Card from 'Utils/Cards/Card';
import Listado from "./Listado";
import ListadoCosto from "./ListadoCosto";
import ConnectedCategoriaModal from './Modal/CategoriaModal';

const SolidPlant = (props) => {
    const { readAllShow, data, loader, readShow, data_modal } = props;
    const { results } = data_modal;
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        readAllShow();
    }, [readAllShow]);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setInitialValues({});
    };

    return (
        <div className="mb-4 col-12">
            <Card
                noShadow
                icon="Side_activos/Admin.svg"
                titulo="PLANTA SOLIDOS"
                subtitulo="RESUMEN"
            >
                <div className="row col-md-12 p-0">
                    <div className="col-md-6">
                        <h4 className="mr-2 titulo_principal">PLANTAS SOLIDOS</h4>
                    </div>
                </div>
                <Listado data={data} loader={loader} openModal={openModal} readShow={readShow} {...props} />
                <div className="row col-md-12 p-0">
                    <div className="col-md-6">
                        <h4 className="mr-2 titulo_principal">COSTO TOTAL POR EMPAQUE DE CADA RECETA APLICADA</h4>
                    </div>
                </div>
                <ListadoCosto data={data} loader={loader} openModal={openModal} readShow={readShow} {...props} />
            </Card>

            <ConnectedCategoriaModal
                open={open}
                closeModal={closeModal}
                initialValues={results || initialValues}
                {...props}
            />
        </div>
    );
};

export default SolidPlant;
