import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import ListadoCosto from './ListadoCosto';

class LogisticsAndExportListSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }

    componentDidMount() {
        this.props.read();
    }

    render() {
        const { data, loader, readShow} = this.props;
        
        return (
            <div className="mb-4 col-12">
                <Card
                    noShadow
                    icon="Side_activos/Admin.svg" 
                    titulo="LOGÍSTICA Y EXPORTACIÓN"
                    subtitulo="RESUMEN"
                >
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">LOGÍSTICA Y EXPORTACIÓN</h4>
                        </div>
                    </div>
                    <Listado data={data} loader={loader} readShow={readShow} {...this.props} />
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">COSTO POR KILOGRAMO POR PUERTO DE SALIDA</h4>
                        </div>
                    </div>
                    <ListadoCosto data={data} loader={loader} openModal={this.openModal} readShow={readShow} {...this.props} />
                </Card>
            </div>
        );
    }
}

export default LogisticsAndExportListSummary;
