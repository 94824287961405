import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable } from 'react-bootstrap-table';
import LoadMask from "../LoadMask/LoadMask";
import { PAGINATION_SIZE_PER_PAGE } from 'constants';


export default class GridEmpty extends Component {
    static propTypes = {
        data: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]).isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
        bordered: true,
    };

    render() {
        const {
            loading,
            data,
            page,
            remote=true,
            expandableRow,
            expandComponent,
            cellEditProp,
            afterSave,
            onPageChange,
            onSortChange,
            pagination,
            expanding,
            onExpand,
            trClassName,
            bordered,
            noShowLoader,
            noShowLoaderInfo,
            titleLoader,
            messageLoader,
            noShowInfoColumn,
            tiitleGrid = '',
            componentView,
            ...other
        } = this.props;
        const options = {
            sizePerPage: PAGINATION_SIZE_PER_PAGE,  // Debe coincidir con el tamaño de página definido en la API
            hideSizePerPage: true,
            paginationSize: 5,
            alwaysShowAllBtns: true,
            noDataText: loading ? 'Cargando...' : 'No hay datos',
            page,
            onPageChange: onPageChange ? onPageChange : () => {},
            onSortChange: onSortChange ? onSortChange : () => {},
            onExpand:onExpand
        };

        const paginar = !(pagination === false);
        return (
            <div>
                {tiitleGrid && <h3>{tiitleGrid}</h3>}
                {componentView && componentView}
                <LoadMask loading={loading} dark blur
                    title={titleLoader} message={messageLoader} noShowLoader={noShowLoader} noShowLoaderInfo={noShowLoaderInfo} noShowInfoColumn={noShowInfoColumn}
                >
                    <BootstrapTable
                        expandableRow={expandableRow}
                        expandComponent={expandComponent}
                        trClassName={trClassName}
                        cellEdit={cellEditProp}
                        data={loading ? [] : data}
                        afterSaveCell={afterSave}
                        remote={remote}
                        pagination={paginar}
                        // fetchInfo={{ dataTotalSize: data.count }}
                        options={options}
                        bordered={bordered}
                        {...other}
                    />
                </LoadMask>
            </div>

        )
    }
}
