import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Grid from "../../../../Utils/Grid";
import { months } from '../../../../Utils/months';
const Listado = props => {
    const { data, eliminar, loader, onSortChange } = props;
    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero);
    }
    const meses = months

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            width='140'
        >
            {index + 1}
        </TableHeaderColumn>
    ));
    return (
        <Grid hover striped data={!data || !Array.isArray(data.results) ? [] : data} loading={loader} onSortChange={onSortChange} pagination={false}
            className="pb-3"
        >

            <TableHeaderColumn
                dataField="name"
                dataSort
                width='180'
            >
                PRESUPUESTO
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                isKey
                width='100'
                dataSort
                dataFormat={standardActions({ editar: "configuration", eliminar })}
            >
                ACCIONES
            </TableHeaderColumn>
        </Grid>
    )
};
export default Listado;