import { api } from "api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SearchInput from "Utils/SearchInput";
import classNames from "classnames";
import Select from "react-select";
import Async from 'react-select/async';
import {tipos_bodega, LATEX, SOLIDO} from '../../../../utility/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CardEmpty from "Utils/Cards/CardEmpty";

const getFincas = (search) => {
    return api.get("finca", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
        });
};

export default class Header extends Component {
    render() {
        const { search, to, textBtn, searchChange, filtro, onChange, filtroFinca, filtraDiaInicio, filtroDiaFinal, id_finca, fechaFinal,
            fechaInicio, fecha_inicio, fecha_final, filtroLiquidacionesPendientes, filtroFincaChangeLiq, fechaFinalLiq,
            fechaInicioLiq, fecha_final_liq, fecha_inicio_liq, dataLiquidaciones, filtroLiquidacionesLiquidadas,
            filtroFincaChangeLiqLiquidadas, fechaFinalLiqLiquidadas, fechaInicioLiqLiquidadas, fecha_inicio_liq_liquidadas, fecha_final_liq_liquidadas} = this.props;

        // Variables para mostrar los resultados de los totales de kilos secos
        var kilos_secos_latex = 0;
        var kilos_secos_solidos = 0;
        var kilos_secos_totales = 0;
        // Variables para mostrar los resultados de los totales de dinero
        var total_latex = 0;
        var total_solidos = 0;
        var total = 0;

        // Verifica que se esta mandando la data de las liquidaciones para poder generar los resultados
        // de lo contrario sigue seteando los datos como 0
        if (dataLiquidaciones) {
            if (dataLiquidaciones.results) {
                for (let i = 0; i < dataLiquidaciones.results.length; i++) {
                    if (dataLiquidaciones.results[i].detalles) {
                        for (let j = 0; j < dataLiquidaciones.results[i].detalles.length; j++) {
                            if (dataLiquidaciones.results[i].detalles[j].ingreso.tipo_materia.tipo === LATEX) {
                                kilos_secos_latex += dataLiquidaciones.results[i].detalles[j].kilos_secos;
                                total_latex += dataLiquidaciones.results[i].detalles[j].total_quetzales;
                            }
                            if (dataLiquidaciones.results[i].detalles[j].ingreso.tipo_materia.tipo === SOLIDO) {
                                kilos_secos_solidos += dataLiquidaciones.results[i].detalles[j].kilos_secos;
                                total_solidos += dataLiquidaciones.results[i].detalles[j].total_quetzales;
                            }
                        }
                    }
                }
                kilos_secos_totales = kilos_secos_latex + kilos_secos_solidos;
                total = total_latex + total_solidos;
            }
        }


        return (
            <div className="d-flex">
                <div className="d-flex flex-1">
                    <div className="row col-12">
                        {(to !== undefined) && (
                            <Link to={to} className="btn btn-primary mb-3">{textBtn}</Link>
                        )}
                        {(filtro !== undefined) && (
                            <div className="col-12 col-lg-3">
                                <Select
                                    className={classNames('react-select-container')}
                                    backspaceRemovesValue={false}
                                    isSearchable={false}
                                    isClearable
                                    options={tipos_bodega}
                                    placeholder="Filtrar tipo"
                                    onChange={(e) => {onChange(e ? e : null); }}
                                    value={filtro}
                                />

                            </div>
                        )}
                        {(filtroFinca !== undefined) && (
                            <div className="col-12 col-lg-3 mt-3 mb-3">
                                <Async
                                    className={classNames('react-select-container')}
                                    backspaceRemovesValue={false}
                                    isSearchable={true}
                                    isClearable
                                    placeholder="Finca"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={getFincas}
                                    onChange={
                                        e => {
                                            filtroFinca(e ? e.id : -1);
                                        }
                                    }
                                    value={id_finca}

                                    getOptionValue={(option) => (option["id"])}
                                    getOptionLabel={(option) => (option["nombre"])}
                                />
                            </div>
                        )}
                        {(filtraDiaInicio !== undefined) && (
                            <div className="col-12 col-lg-3 mt-3 mb-3 row" style={{borderRadius: "1em"}}>
                                <div className="col-5 p-0 text-right">
                                    <label className="mb-1 mt-1 mr-2">Fecha inicio</label>
                                </div>
                                <div className="col-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha de inicio"
                                        onChange={(value) => {fechaInicio(value)}}
                                        selected={fecha_inicio}
                                    />
                                </div>
                            </div>
                        )}
                        {(filtroDiaFinal !== undefined) && (
                            <div className="col-12 col-lg-3 mt-3 mb-3 row" style={{borderRadius: "1em"}}>
                                <div className="col-5 p-0 text-right">
                                    <label className="mb-1 mt-1 mr-2">Fecha final</label>
                                </div>
                                <div className="col-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha final"
                                        onChange={(value) => {fechaFinal(value)}}
                                        selected={fecha_final}
                                    />
                                </div>
                            </div>
                        )}
                        {(filtroLiquidacionesPendientes !== undefined) && (
                            <div className="col-12 row">
                                <div className="col-lg-4 col-12">
                                    <div className="col-12 mt-3 mb-3">
                                        <label className="mb-1 mt-1">Finca</label>
                                        <Async
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder=""
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={getFincas}
                                            onChange={
                                                e => {
                                                    filtroFincaChangeLiq(e ? e.id : -1);
                                                }
                                            }
                                            value={id_finca}

                                            getOptionValue={(option) => (option["id"])}
                                            getOptionLabel={(option) => (option["nombre"])}
                                        />
                                    </div>
                                    <div className="col-12 mb-3 row mr-0 pr-0">
                                        <div className="col-6 mr-0 pr-0">
                                            <label className="mb-1 mt-1">Fecha inicio</label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                dateFormat="DD/MM/YYYY"
                                                placeholderText="Fecha de inicio"
                                                onChange={(value) => {fechaInicioLiq(value)}}
                                                selected={fecha_inicio_liq}
                                            />

                                        </div>
                                        <div className="col-6 mr-0 pr-0">
                                            <label className="mb-1 mt-1">Fecha final</label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                dateFormat="DD/MM/YYYY"
                                                placeholderText="Fecha final"
                                                onChange={(value) => {fechaFinalLiq(value)}}
                                                selected={fecha_final_liq}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-12 mb-4">
                                    <div className="text-center">
                                        <h6>TOTALES KILOS SECOS</h6>
                                    </div>
                                    <CardEmpty noShadow>
                                        <div className="fondo-totales mr-2 mb-2">
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label className="gris">Solidos</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-md azul">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_solidos)}
                                                        </span>
                                                        <span className="gris text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label className="gris">Latex</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-md azul">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_latex)}
                                                        </span>
                                                        <span className="gris text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label>TOTAL</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-lg gris">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_totales)}
                                                        </span>
                                                        <span className="azul text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                                <div className="col-lg-4 col-12 mb-4">
                                    <div className="text-center">
                                        <h6>TOTALES MONETARIOS</h6>
                                    </div>
                                    <CardEmpty noShadow>
                                        <div className="fondo-totales mr-2 mb-2">
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris mr-3 ">Solidos</label>
                                                        <span className="gris text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-md azul">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_solidos)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris mr-3 ">Latex</label>
                                                        <span className="gris text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-md azul">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_latex)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="mr-3 ">TOTAL</label>
                                                        <span className="azul text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-lg gris">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                            </div>
                        )}
                        {(filtroLiquidacionesLiquidadas !== undefined) && (
                            <div className="col-12 row">
                                <div className="col-4">
                                    <div className="col-12 mt-3 mb-3">
                                        <label className="mb-1 mt-1">Finca</label>
                                        <Async
                                            className={classNames('react-select-container')}
                                            backspaceRemovesValue={false}
                                            isSearchable={true}
                                            isClearable
                                            placeholder=""
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={getFincas}
                                            onChange={
                                                e => {
                                                    filtroFincaChangeLiqLiquidadas(e ? e.id : -1);
                                                }
                                            }
                                            value={id_finca}

                                            getOptionValue={(option) => (option["id"])}
                                            getOptionLabel={(option) => (option["nombre"])}
                                        />
                                    </div>
                                    <div className="col-12 mb-3 row mr-0 pr-0">
                                        <div className="col-6 mr-0 pr-0">
                                            <label className="mb-1 mt-1">Fecha inicio</label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                dateFormat="DD/MM/YYYY"
                                                placeholderText="Fecha de inicio"
                                                onChange={(value) => {fechaInicioLiqLiquidadas(value)}}
                                                selected={fecha_inicio_liq_liquidadas}
                                            />

                                        </div>
                                        <div className="col-6 mr-0 pr-0">
                                            <label className="mb-1 mt-1">Fecha final</label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                dateFormat="DD/MM/YYYY"
                                                placeholderText="Fecha final"
                                                onChange={(value) => {fechaFinalLiqLiquidadas(value)}}
                                                selected={fecha_final_liq_liquidadas}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-4 mb-4">
                                    <div className="text-center">
                                        <h6>TOTALES KILOS SECOS</h6>
                                    </div>
                                    <CardEmpty noShadow>
                                        <div className="fondo-totales mr-2 mb-2">
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label className="gris">Solidos</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-md azul">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_solidos)}
                                                        </span>
                                                        <span className="gris text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label className="gris">Latex</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-md azul">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_latex)}
                                                        </span>
                                                        <span className="gris text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <label>TOTAL</label>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <span className="text-lg gris">
                                                            {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2, maximumFractionDigits:2}).format(kilos_secos_totales)}
                                                        </span>
                                                        <span className="azul text-sm mb-1">KG</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                                <div className="col-4 mb-4">
                                    <div className="text-center">
                                        <h6>TOTALES MONETARIOS</h6>
                                    </div>
                                    <CardEmpty noShadow>
                                        <div className="fondo-totales mr-2 mb-2">
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris mr-3 ">Solidos</label>
                                                        <span className="gris text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-md azul">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_solidos)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="gris mr-3 ">Latex</label>
                                                        <span className="gris text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-md azul">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total_latex)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mb-1">
                                                <div className="d-flex justify-content-between align-items-end">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <label className="mr-3 ">TOTAL</label>
                                                        <span className="azul text-sm mb-1">Q.</span>
                                                    </div>
                                                    <span className="text-lg gris">
                                                        {new Intl.NumberFormat("gt-GT", {minimumFractionDigits:2}).format(total)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                    {(search !== undefined) && (
                        <SearchInput search={search} searchChange={searchChange} />
                    )}
            </div>
        );
    }
}
