const validate = values =>{
    const errors={}
    const message = 'Campo requerido'
    if(!values.name){
        errors.name = message
    }

    
    if (typeof values.category_budget !== 'undefined') {
        const categoryBudgetArrayErrors = [];
        values.category_budget.forEach((category, categoryIndex) => {
            const categoryErrors = {};
    
            if (category && category.category_table && category.category_table.length > 0) {
                const categoryTableArrayErrors = [];
                category.category_table.forEach((table, tableIndex) => {
                    const tableErrors = {};
    
                    if (table && table.generic_row && table.generic_row.length > 0) {
                        const genericRowArrayErrors = [];
                        table.generic_row.forEach((row, rowIndex) => {
                            const rowErrors = {};
                            if (!row || !row.name) {
                                rowErrors.name = "requerido";
                                genericRowArrayErrors[rowIndex] = rowErrors;
                            }
                            
                        });
                        if (genericRowArrayErrors.length) {
                            tableErrors.generic_row = genericRowArrayErrors;
                        }
                    }
    
                    if (Object.keys(tableErrors).length) {
                        categoryTableArrayErrors[tableIndex] = tableErrors;
                    }
                });
                if (categoryTableArrayErrors.length) {
                    categoryErrors.category_table = categoryTableArrayErrors;
                }
            }
    
            if (Object.keys(categoryErrors).length) {
                categoryBudgetArrayErrors[categoryIndex] = categoryErrors;
            }
        });
        if (categoryBudgetArrayErrors.length) {
            errors.category_budget = categoryBudgetArrayErrors;
        }
    }
    
    return errors
}
export default validate;