import React from 'react';
import Select, { Creatable, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from "react-datepicker";
import _ from "lodash";
import TimePicker from 'react-time-picker';
import TagsInput from 'react-tagsinput'
import moment from 'moment';

export const renderField = ({
    input, placeholder, type, disabled, addClass, style, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                type={type}
                style={style}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextArea = ({
    input, placeholder, disabled, rows, meta: { touched, error },
}) => {
    const invalid = touched && error;
    return (
        <div>
            <textarea
                {...input}
                placeholder={placeholder}
                disabled={disabled}
                style={{ resize: 'none' }}
                rows={rows || 3}
                className={classNames('form-control', { 'is-invalid': invalid })}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCM2 = ({
    input, decimalScale, placeholder, meta: { touched, error }, prefix = "", suffix = "cm2", numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumber = ({
    input,
    decimalScale,
    placeholder,
    meta: { touched, error },
    prefix = "",
    suffix = "",
    numberFormat,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames("form-control", {
                    "is-invalid": invalid,
                })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderNumberBill = ({
    input, decimalScale, placeholder, meta: { touched, error, dirty }, prefix = "", suffix = "",
    numberFormat, onCambio, disabled
}) => {
    const invalid = error ? true : false;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberPercentage = ({
    input, decimalScale, placeholder, meta: { touched, error, dirty }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    const invalid = error ? true : false;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" %"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberPercentageFixed = ({
    input, decimalScale, placeholder, meta: { touched, error, dirty }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    const invalid = error ? true : false;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" %"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberKG = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    // Espera a que el campo sea 'tocado' para verificar si tiene algún error de validación
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" Kg"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderNumberUnits = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    // Espera a que el campo sea 'tocado' para verificar si tiene algún error de validación
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix="  U"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderNumberKGDisabled = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio
}) => {
    // Espera a que el campo sea 'tocado' para verificar si tiene algún error de validación
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control-disabled', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" Kg"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
                disabled
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderNumberDisabled = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio
}) => {
    // Espera a que el campo sea 'tocado' para verificar si tiene algún error de validación
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control-disabled', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=""
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
                disabled
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberCM = (props) => {
    const {
        input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
        numberFormat, onCambio, disabled
    } = props;
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale !== undefined ? decimalScale : 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" cm"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberG = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 4}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" g"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumberML = ({
    input, placeholder, decimalScale, meta: { touched, error }, prefix = "",
    numberFormat, onCambio, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 4}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix=" ml"
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};


export const renderCurrency = ({
    input, meta: { touched, error }, prefix = "Q. ", placeholder, disabled, decimalScale,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                disabled={disabled}
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrencyDisabled = ({
    input, meta: { touched, error }, prefix = "Q. ", placeholder, decimalScale,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control-disabled', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                disabled
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderNumeroSinFormato = ({
    input, meta: { touched, error }, placeholder, disabled, decimalScale,
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale ? decimalScale : 2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                disabled={disabled}
                // prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderDollarSiDecimal = ({
    input, decimalScale, placeholder, meta: { touched, error, dirty }, prefix = "$ ",
    numberFormat, onCambio, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                // suffix="$ "
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderDollar = ({
    input, decimalScale, placeholder, meta: { touched, error, dirty }, prefix = "$ ",
    numberFormat, onCambio, disabled
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames('form-control', { 'is-invalid': invalid })}
                decimalScale={decimalScale || 2}
                format={numberFormat}
                fixedDecimalScale
                disabled={disabled}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                // suffix="$ "
                onValueChange={(values) => {
                    input.onChange(values.value);
                    if (!!onCambio) {
                        onCambio(values.floatValue)
                    }
                }}
                onBlur={() => {
                    input.onBlur(input.value)
                }}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderSwitch = ({
    input, meta: { touched, error }, label, disabled, checked
}) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                offColor="#888"
                onColor="#035B2F"
                height={24}
                width={52}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);

                }}
                checked={checked !== undefined ? checked : input.value ? input.value : false}
                id="normal-switch"
                handleDiameter={18}
                uncheckedIcon={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: '#fff',
                            paddingRight: 2,
                        }}
                    >
                        No
                    </div>
                }
                checkedIcon={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            fontSize: 12,
                            color: '#fff',
                            paddingLeft: 2,
                        }}
                    >
                        Si
                    </div>
                }
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitchMessage = ({
    input, message,
}) => {
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
            />
        </div>
    );
};

export const renderFieldCheck = ({ input, label, disabled, onChange, meta: { touched, error } }) => {
    // ...

    return (
        <div className="checkbox c-checkbox">
            <label className="needsclick">
                <input
                    type="checkbox"
                    disabled={disabled}
                    {...input}
                    checked={input.value ? true : false}
                />
                {label}&nbsp;
                <span className="fa fa-check ml-2" />
            </label>
        </div>
    );
};


export const renderFieldCheckOne = ({ input, label, type, meta: { touched, error }, customOnChange }) => {
    return (
        <div className="checkbox c-checkbox">
            <label className="needsclick">
                <input
                    type={type}
                    {...input}
                    onChange={(event) => {
                        // Actualiza el valor del checkbox
                        input.onChange(event.target.checked);

                        // Luego, si se proporciona una función customOnChange, la llama con el nombre del campo y el estado actual del checkbox (checked or not)
                        if (customOnChange) {
                            customOnChange(input.name, event.target.checked);
                        }
                    }}
                />
                {label}&nbsp;
                <span className="fa fa-check ml-2" />
            </label>
            {touched && error && <span className="text-danger">{error}</span>}
        </div>
    );
};


export const renderFieldRadio = ({ input, label, value, id, disabled,checked, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="font-weight-bold">
                    <input
                        type="radio"
                        disabled={disabled}
                        id={id}
                        value={value}
                        checked={checked}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    {label}&nbsp;
                    <span />
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};
export const SelectFieldAllFields = ({
    input,
    isMulti,
    options,
    disabled,
    onCambio,
    objAsValue = true, // Cambiado a true para manejar objetos completos
    onCambioObj,
    placeholder,
    isClearable,
    isSearchable,
    is_departamentos,
    labelKey = "label",
    valueKey = "value",
    meta: { touched, error, dirty }
}) => {

    const invalid = Boolean((dirty && error) || (touched && error));
    const _options = [];
    if (is_departamentos) {
        Object.keys(options).forEach((option, index) => {
            _options.push({ ...option, label: options[option], value: option });
        });
    } else {
        options.forEach(option => {
            _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
        });
    }
    let value = input.value;
    if (value !== null && value !== undefined) {
        if ((typeof value) === 'object' && objAsValue) {
            value = _options.find(option => option.value === value[valueKey]);
        } else {
            value = _options.find(option => option.value === value);
        }
    }
    if (value === undefined) {
        value = null;
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '1.5rem',
            fontSize: '12px',
            minHeight: '0',
            border: state.isFocused ? '1px solid #035192' : '1px solid #ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(3, 81, 146, 0.25)' : 'none'
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: '14px'
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: '0 8px 0 2px'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: 'white'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        })
    };

    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(selectedOption) => {
                    if (objAsValue) {
                        input.onChange(selectedOption);
                    } else {
                        input.onChange(selectedOption ? selectedOption[valueKey] : null);
                    }
                    if (!!onCambio) {
                        onCambio(selectedOption ? selectedOption['id'] : null);
                    }
                    !!onCambioObj && onCambioObj(selectedOption);
                }}
                value={value}
                isDisabled={disabled}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export const SelectField = (
    {
        input,
        isMulti,
        options,
        disabled,
        onCambio,
        objAsValue = false,   // Si es true, a input.onChange() se pasa el item y no item[valueKey]
        onCambioObj,
        placeholder,
        isClearable,
        isSearchable,
        is_departamentos,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error, dirty }
    }) => {

    const invalid = Boolean((dirty && error) || (touched && error));
    const _options = [];
    if (is_departamentos) {
        {
            Object.keys(options).forEach((option, index) => {
                _options.push({ ...option, label: options[option], value: option })
            })
        }
    } else {
        options.forEach(option => {
            _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
        });
    }
    let value = input.value;
    if (value !== null && value !== undefined) {
        if ((typeof value) === 'object') {
            value = _.find(_options, { value: value[valueKey] });
        } else {
            value = _.find(_options, { value });
        }
    }
    if (value === undefined) {
        value = null
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '1.5rem',
            fontSize: '12px',
            minHeight: '0',
            border: state.isFocused ? '1px solid #035192' : '1px solid #ced4da', // Ajusta el borde basado en el estado
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(3, 81, 146, 0.25)' : 'none' // Ajusta la sombra del foco
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: '14px'
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: '0 8px 0 2px'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: 'white'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        })
    };
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={"Seleccione..."}
                onChange={(e) => {
                    input.onChange(e ? (objAsValue ? e : e[valueKey]) : null);
                    if (!!onCambio) {
                        onCambio(e ? e['id'] : null)
                    }
                    !!onCambioObj && onCambioObj(e);
                }}
                // onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};
export const SelectMunicipioField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];

    {
        options.forEach((option) => {
            _options.push({ ...option, label: option.nombre, value: option.id })
        })
    }
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '1.5rem',
            fontSize: '12px',
            minHeight: '0',
            border: state.isFocused ? '1px solid #035192' : '1px solid #ced4da', // Ajusta el borde basado en el estado
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(3, 81, 146, 0.25)' : 'none' // Ajusta la sombra del foco
        }),
        placeholder: (provided, state) => ({
            ...provided,
            fontSize: '14px'
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            padding: '0 8px 0 2px'
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: 'white'
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        }),
        menu: (provided, state) => ({
            ...provided,
            fontSize: '12px'
        })
    };
    return (
        <React.Fragment>
            <Select
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={"Seleccione..."}
                onChange={(e) => { input.onChange(e ? e[valueKey] : null); }}
                value={value}
                isDisabled={disabled}
                styles={customStyles}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const AsyncSelectField = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    valueKey,
    labelKey,
    onCambio,
    valor,
    isMulti,
    meta: { touched, error }

}) => {
    const invalid = touched && error;

    // Estilos personalizados para el react-select
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: '1.5rem',
            fontSize: '12px',
            minHeight: '0',
            border: state.isFocused ? '1px solid #035192' : '1px solid #ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(3, 81, 146, 0.25)' : 'none'
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px'
        }),
        indicatorContainer: (provided) => ({
            ...provided,
            padding: '0 8px 0 2px'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'white'
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '12px'
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '12px',
            zIndex: 9999
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        })
    };

    return (
        <React.Fragment>
            <AsyncSelect
                isClearable={isClearable}
                cacheOptions
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : "Seleccione..."}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (!!onCambio) {
                        onCambio(e ? e : null)
                    }
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (option[labelKey])}
                isMulti={isMulti}
                styles={customStyles}
                onBlur={() => {
                    input.onBlur(input.value);
                }}
                menuPortalTarget={document.body} // Renderiza el menú del select en el body
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};
const AsyncSelectFields = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    meta: { touched, error }
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <AsyncSelect
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : "Seleccione..."}
                onChange={(e) => input.onChange(e ? e : null)}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    );
};

export default AsyncSelectFields;

export const AsyncSelectFieldFinca = ({
    input,
    disabled,
    isClearable,
    isSearchable,
    loadOptions,
    placeholder,
    valueKey,
    labelKey,
    onCambio,
    valor,
    isMulti,
    meta: { touched, error }
}) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder ? placeholder : "Seleccione..."}
                onChange={(e) => {
                    input.onChange(e ? e : null);
                    if (!!onCambio) {
                        onCambio(e ? e : null)
                    }
                }}
                value={input.value}
                isDisabled={disabled}
                getOptionValue={(option) => (option[valueKey])}
                getOptionLabel={(option) => (`${option['nombre']} - ${option['razon_social']}`)}
                isMulti={isMulti}
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey = "label",
        valueKey = "value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({ ...option, label: option[labelKey], value: option[valueKey] });
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({ photo, setFile, className, disabled, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                disabled={disabled}
                img={!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPicker = ({ className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({ className, disabled, onCambio, maxDate, minDate, todayButton, addClass, dateFormat, placeholder, showPopperArrow, fecha_inicial, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                showPopperArrow={showPopperArrow ? showPopperArrow : false}
                dateFormat={dateFormat ? dateFormat : "DD/MM/YYYY"}
                placeholderText={placeholder}
                onChange={(e) => {
                    input.onChange(e);
                    if (!!onCambio) {
                        onCambio(e)
                    }
                }}
                selected={input.value ? moment(input.value) : fecha_inicial}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                todayButton={todayButton}
                className={classNames('form-control', { 'is-invalid': invalid }, addClass)}
            />
            {invalid && (
                <div className="invalid-force">
                    {error}
                </div>
            )}
        </div>
    )
};

export const renderTimeField = ({ required, name, className, input, label, disabled, type, meta: { touched, error } }) => {
    const valueIsValid = (value) => {
        if (value instanceof Date) {
            return true;
        }
        return value && value.length === 5 && value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    };
    const invalid = touched && error;
    return (
        <div className={classNames({ 'is-invalid': invalid })}>
            <TimePicker
                maxDetail="minute"
                locale="en-US"
                disableClock={true}
                clearIcon={null}
                name={name}
                value={input.value}
                onChange={(value) => {
                    if (valueIsValid(value)) {
                        input.onChange(value);
                    }
                }}
                disabled={disabled} />
            {invalid && <div className="invalid-force" >
                {error}
            </div>}
        </div>
    )
};

export const renderTagsInput = ({ input, placeholder }) => {
    const keys = [9, 13, 32];
    return (
        <TagsInput
            value={input.value ? input.value : []}
            onChange={(value) => { input.onChange(value) }}
            addKeys={keys}
            inputProps={{
                className: 'form-control-inline',
                placeholder: placeholder
            }}
        />
    )
};

export const renderTypeOfDebtDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
    applyStyles = true,
    isTextarea = true,
    labels = {}  // Recibe las etiquetas como parámetro opcional
}) => {
    const invalid = touched && error;

    // Si `labels` está presente y tiene un valor para el input, mostramos la etiqueta
    const label = labels[input.value] || null;

    const commonStyles = {
        border: "0",
        overflow: "hidden",
        resize: "none",
        height: "auto",
    };

    const inputProps = {
        ...input,
        placeholder,
        type,
        className: classNames(
            "form-control-disabled",
            { "is-invalid": invalid }
        ),
        disabled: true,
    };

    return (
        <div>
            {label ? (
                // Mostrar el valor mapeado (label) en lugar del textarea o input si existe
                <input
                    value={label}
                    type="text"
                    className={inputProps.className}
                    disabled={true}
                    style={applyStyles ? { ...commonStyles, textTransform: "uppercase", fontSize: '13px', fontWeight: "bold" } : { border: "0" }}
                />
            ) : (
                // Si no hay etiqueta, renderizamos el textarea o input deshabilitado
                isTextarea ? (
                    <textarea
                        {...inputProps}
                        style={applyStyles ? commonStyles : { border: "0" }}
                    />
                ) : (
                    <input
                        {...inputProps}
                        style={applyStyles ? commonStyles : {
                            border: "0",
                            textTransform: "uppercase",
                            fontSize: '13px',
                            fontWeight: "bold"
                        }}
                    />
                )
            )}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
export const renderFieldDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
    applyStyles = true,
    isTextarea = true,
}) => {
    const invalid = touched && error;

    const commonStyles = {
        border: "0",
        textAlign: "center",
        overflow: "hidden",
        resize: "none",
        height: "auto",
    };

    const inputProps = {
        ...input,
        placeholder,
        type,
        className: classNames(
            "form-control-disabled",
            { "is-invalid": invalid }
        ),
        disabled: true,
    };

    return (
        <div>
            {isTextarea ? (
                <textarea
                    {...inputProps}
                    style={applyStyles ? commonStyles : { border: "0" }}
                />
            ) : (
                <input
                    {...inputProps}
                    style={applyStyles ? commonStyles : {
                        border: "0",
                        textTransform: "uppercase",
                        fontSize: '13px',
                        fontWeight: "bold"
                    }}
                />
            )}
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};


export const renderDollarDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
    disabledStyle,
    decimalScale,
}) => {
    const invalid = touched && error;
    const scale = (decimalScale !== undefined && decimalScale !== null) ? decimalScale : 2;
    return (
        <div>
            <NumberFormat
                {...input}
                placeholder={placeholder}
                type={type}
                style={{
                    border: "0",
                    textAlign: "center",
                    ...disabledStyle  // Agregar estilos dinámicos aquí
                }}
                prefix="$ "
                thousandSeparator
                fixedDecimalScale
                decimalScale={scale}
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid }
                )}
                disabled
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
export const renderNumberSinFormatoDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
    Decimals = true
}) => {
    const invalid = touched && error;
    return (
        <div>
            <NumberFormat
                {...input}
                placeholder={placeholder}
                type={type}
                style={{ border: "0", textAlign: "center" }}
                // prefix = "$"
                thousandSeparator
                fixedDecimalScale={Decimals}
                decimalScale={2}
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid }
                )}
                disabled
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

export const renderQuetzalesDisabled = ({
    input,
    placeholder,
    type,
    decimalScale,
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const scale = (decimalScale !== undefined && decimalScale !== null) ? decimalScale : 2;
    return (
        <div>
            <NumberFormat
                {...input}
                placeholder={placeholder}
                type={type}
                style={{ border: "0", textAlign: "center" }}
                thousandSeparator
                decimalScale={scale}
                fixedDecimalScale
                prefix="Q "
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid }
                )}
                disabled
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
export const renderPercentageDisabled = ({
    input,
    placeholder,
    type,
    meta: { touched, error },
    style
}) => {
    const invalid = touched && error;
    const defaultStyles = { border: "0", textAlign: "center" };
    const combinedStyles = style ? { ...style } : defaultStyles;
    return (
        <div>
            <NumberFormat
                {...input}
                placeholder={placeholder}
                type={type}
                style={combinedStyles}
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
                suffix=" %"
                className={classNames(
                    "form-control-disabled",
                    { "is-invalid": invalid }
                )}
                disabled
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
export const RenderField = {
    renderField,
    renderTextArea,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderSwitchMessage,
    renderFieldCheck,
    renderFieldRadio,
    renderCM2,
    renderTimeField,
    renderFieldDisabled,
    renderQuetzalesDisabled,
    renderDollarDisabled,
    renderPercentageDisabled,
    renderNumberUnits,
    renderTypeOfDebtDisabled
};
