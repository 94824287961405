import React from 'react';
import { Field, reduxForm, FieldArray, submit } from 'redux-form';
import ShoppingTable from "./Tables/ShoppingTable"
import ShoppingLatexTable from './Tables/ShoppingLatexTable';
import ShoppingChipaTable from './Tables/ShoppingChipaTable';
import { renderField, renderFieldDisabled } from "../../../../Utils/renderField/renderField"
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import { connect } from 'react-redux';
import ConstantsTable from '../../configuration/Edit/Tables/ConstantsShopping'

const renderTable = ({ fields, dollar_exchange, change }) => (
    <div>
        {fields.map((row, index) => (
            <div key={index} className='col-sm-12 p-0'>
                <ShoppingTable fieldName={`${row}.generic_row`} dollar_exchange={dollar_exchange} change={change} />
            </div>
        ))}
    </div>
);

const renderCategory = ({ fields, dollar_exchange, change}) => {
    return (
        <div>
            {fields.map((table, index) => (
                <div key={index} className='col-sm-12 p-0'>
                    <div className="mt-2">
                        <Field
                            name={`${table}.name`}
                            component={renderFieldDisabled}
                            width="50%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                        <Field
                            name={`${table}.description`}
                            component={renderField}
                            style={{ display: 'none' }}
                            width="50%"
                        />
                    </div>
                    <FieldArray name={`${table}.category_table`} dollar_exchange={dollar_exchange} change={change} component={renderTable} />
                </div>
            ))}
        </div>
    );
}

let ShoppingForm = props => {
    const { handleSubmit, editar, item, formValues, data } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardFormNoBorder
                    icon="Side_activos/Compras.svg"
                    titulo="PRESUPUESTO DE COMPRAS"
                    subtitulo="RESUMEN"
                    subtituloForm="DETALLE"
                    col="12"
                    noShadow
                >
                </CardFormNoBorder>
                {data.is_farm_budget_completed &&
                    data.is_sales_budget_completed && (
                        <React.Fragment>
                            <CardEmpty isMarginButtom>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            <strong>
                                                COSTOS POR COMPRAS DE LATEX
                                            </strong>
                                        </h5>
                                    </div>
                                </div>
                                <ShoppingLatexTable {...props} />
                            </CardEmpty>
                            <CardEmpty isMarginButtom>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            <strong>
                                                COSTOS POR COMPRAS DE CHIPA
                                            </strong>
                                        </h5>
                                    </div>
                                </div>
                                <ShoppingChipaTable {...props} />
                            </CardEmpty>
                            <CardEmpty isMarginButtom >
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h6 className="mb-4"><strong>Fletes y comisiones de compras</strong></h6>
                                    </div>
                                </div>
                                <ConstantsTable
                                    {...props}
                                />
                            </CardEmpty>
                            <CardEmpty>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            RUBROS -PRESUPUESTO DE COMPRAS
                                        </h5>
                                    </div>
                                </div>
                                <FieldArray
                                    name="category_budget"
                                    dollar_exchange={item}
                                    component={renderCategory}
                                    change={props.change}
                                />
                            </CardEmpty>
                        </React.Fragment>
                    )}
            </div>
        </form>
    )
};

const mapStateToProps = (state) => {
    const formValues = state.form.ShoppingForm || {};
    return { formValues };
};

ShoppingForm = reduxForm({
    form: 'ShoppingForm'
})(ShoppingForm);


export default connect(mapStateToProps)(ShoppingForm);