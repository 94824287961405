import React, { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled, renderCurrency, renderQuetzalesDisabled, renderFieldCheck } from '../../../../../Utils/renderField/renderField';
import { renderField } from "../../../../../Utils/renderField/renderField"
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months as meses } from '../../../../../Utils/months';


const TableRow = props => {
    const { system, index, fields, dollar_exchange, kg_proyectados } = props;

    const total_kg_proyectados = (kg_proyectados_data, months) => {

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(kg_proyectados_data[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);
        return totalForRow;

    }
    const months = meses
    const isNew = fields.get(index).isNew;
    const kg_proyectados_data = kg_proyectados.values.kg_proyectados.generic_row[0]

    const calculateTotalForMonth = (row, month, isDollar) => {

        let value = parseFloat(row[month]) || 0;

        if (kg_proyectados_data && kg_proyectados_data[month]) {
            value /= kg_proyectados_data[month];
        }
        if (row.is_dollar && dollar_exchange) {
            value *= dollar_exchange;
        }
        if (isDollar && dollar_exchange) {
            value /= dollar_exchange;
        }

        return value;
    };


    const calculateTotalForRow = (isDollar) => {
        let kg_proyectados_total = total_kg_proyectados(kg_proyectados_data, months)
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);

        if (isDollar && dollar_exchange) {
            totalForRow /= dollar_exchange;
        }
        if (kg_proyectados && isDollar) {
            totalForRow /= kg_proyectados_total
        }

        return totalForRow;
    };

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top custom-cell" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={isNew ? renderField : renderFieldDisabled}
                        isTextarea={false}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                    Costo por Kg
                </td>
                <td style={{ width: "6.3%" }} className="custom-cell text-center">
                    {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForRow())}
                    <br />
                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow(true))}
                </td>
                {months.map((month, monthIndex) => (
                    <td key={month} style={{ width: "6.4%" }} className="custom-cell text-center">
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={fields.get(index).is_dollar ? renderDollar : renderCurrency}
                            className="form-control"
                            placeholder={fields.get(index).is_dollar ? '$ 0.00' : 'Q 0.00'}
                        />

                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(fields.get(index), month, true))}
                    </td>
                ))}
                <td className='sin-borde-top' style={{ width: '6.4%' }}>
                    <Field

                        name={`${system}.is_iva_credit`}
                        component={renderFieldCheck}
                        className='form-control'
                    />
                </td>
                <td className="sin-borde-top" style={{ width: "15%", position: 'relative' }}>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <img src={require('assets/img/icons/Accion-Eliminar.svg')} alt="Borrar"
                                title="Borrar" className="action_img"
                                onClick={() => {
                                    fields.remove(index)
                                }}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};


const GenericTable = props => {
    const { fields, item, values_kg } = props;
    const months = meses
    const dollar_exchange = item
    // Por carga de la data
    const values_kg_copy = values_kg.values
    var kg_values = 0
    var kg_values_row = 0

    if (values_kg_copy) {
        kg_values = values_kg_copy.kg_proyectados
        if (kg_values) {
            kg_values_row = kg_values.generic_row[0]
        }
    }

    const calculateTotalForMonth = (month, isDollar, isKg) => {


        const allFields = fields.getAll() || [];
        let total = allFields.reduce((total, system) => {
            let value = parseFloat(system[month]) || 0;

            if (system.is_dollar) {
                value *= dollar_exchange;
            }

            return total + value;
        }, 0);

        if (kg_values_row && isKg && kg_values_row[month]) {
            total /= kg_values_row[month];
        }
        if (isDollar && dollar_exchange) {
            total /= dollar_exchange;
        }
        return total;
    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-shopping'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>CONCEPTO</th>
                                    <th style={{ width: "6.4%" }}>TOTAL</th>
                                    {columnas.map(columna => columna)}
                                    <th style={{ width: "6.4%" }}>¿Genera IVA?</th>
                                    <th style={{ width: "6.4%" }}>ACCIONES</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow
                                    key={index}
                                    system={system}
                                    index={index}
                                    fields={fields}
                                    dollar_exchange={dollar_exchange}
                                    kg_proyectados={values_kg}
                                />)}
                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES Q</td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(calculateTotalForMonth(month))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7", borderBottom: '1px solid #035B2F', borderTop: '1px solid #035B2F' }}>
                                    <td className="custom-cell text-center">TOTALES $</td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr style={{ backgroundColor: "#F0FFC7" }}>
                                    <td className="custom-cell text-center">Totales costo por kg</td>
                                    {months.map((month, idx) => (
                                        <td key={idx} className="custom-cell text-center">
                                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForMonth(month, true, true,))}
                                        </td>
                                    ))}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className="mt-2">
                <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end agregar-tab-generica" onClick={() => fields.push({ isNew: true })}>
                    AGREGAR
                </div>
            </div>
        </div>
    );
}

const ProductionLatexTable = props => {
    return (
        <div>
            <FieldArray
                name='budget_finca_latex_table.generic_row'
                component={GenericTable}
                props={props}
            />
        </div>
    );
}

const formName = 'FincaForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {

    const categoryBudget = selector(state, 'generic_row') || [];
    const values_kg = state.form.FincaForm || {};
    const allValues = categoryBudget.map(item => item);
    return { values_kg, allValues };

};

export default connect(mapStateToProps)(ProductionLatexTable);