import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';

const ListadoCosto = (props) => {
    const { data, loader, onSortChange, openModal, readShow } = props;




    const formatDollar = (cell, row) => {
        if (cell && cell !== '--') {
            const formattedValue = parseFloat(cell).toFixed(7);
            return `$ ${formattedValue}`;
        }
        return '--';
    };


    return (
        <div>
            <Grid
                hover
                striped
                data={data.total_costs || []}
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                >
                    Costos de producción por kg / tipo de producto
                </TableHeaderColumn>
                <TableHeaderColumn
                        dataField="CV"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        CV
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="SGR10/20"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        SGR10/20
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="SKIM"
                        dataSort
                        dataFormat={formatDollar}
                    >
                        SKIM
                    </TableHeaderColumn>
            </Grid>
            <br />
        </div>
    );
};

export default ListadoCosto;
