import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/admin/customers';
import {getMe} from "../../../../../../redux/modules/cuenta/login";
import CreateCustomer from './CreateCustomer';

const mstp = state => {
    return {...state.customers}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(CreateCustomer)
