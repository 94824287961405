import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemState
} from 'react-accessible-accordion';
const parametros = require("assets/img/icons/side_inactivos/Parametros.svg");
const parametros_activo = require("assets/img/icons/Side_activos/Parametros.svg");
const ventas = require("assets/img/icons/side_inactivos/ventas.svg");
const ventas_activo = require("assets/img/icons/Side_activos/ventas.svg");
const finca = require("assets/img/icons/side_inactivos/finca.svg");
const finca_activo = require("assets/img/icons/Side_activos/finca.svg");
const compras = require("assets/img/icons/side_inactivos/Compras.svg");
const compras_activo = require("assets/img/icons/Side_activos/Compras.svg");
const plantas = require("assets/img/icons/side_inactivos/plantas.svg");
const plantas_activo = require("assets/img/icons/Side_activos/plantas.svg");
const logistica_exportacion = require("assets/img/icons/side_inactivos/Exportacion.svg");
const logistica_exportacion_activo = require("assets/img/icons/Side_activos/Exportacion.svg");
const administrativos = require("assets/img/icons/side_inactivos/Admin.svg");
const administrativos_activo = require("assets/img/icons/Side_activos/Admin.svg");
const inversiones = require("assets/img/icons/side_inactivos/Inversiones.svg");
const inversiones_activo = require("assets/img/icons/Side_activos/Inversiones.svg");
const deuda = require("assets/img/icons/side_inactivos/deuda.svg");
const deuda_activo = require("assets/img/icons/Side_activos/deuda.svg");
const credito_fiscal = require("assets/img/icons/side_inactivos/creditofiscal.svg");
const credito_fiscal_activo = require("assets/img/icons/Side_activos/creditofiscal.svg");
const reportes = require("assets/img/icons/side_inactivos/tabler-icon-report-analytics.svg");
const reportes_activo = require("assets/img/icons/Side_activos/tabler-icon-report-analytics.svg");
const administracion = require("assets/img/icons/side_inactivos/configuraciones.png");
const administracion_activo = require("assets/img/icons/Side_activos/configuraciones.png");
const vector = require("assets/img/icons/Deslizar/right_inactivo.svg")
const vector_activo = require("assets/img/icons/Deslizar/right_activo.svg")
const elipse = require("assets/img/icons/Deslizar/Ellipse_item.svg")
const elipse_sub = require("assets/img/icons/Deslizar/Ellipse_sub_item.svg")

const MODULO_PARAMETROS = 'PARAMETROS';
const MODULO_VENTAS = 'VENTAS';
const MODULO_FINCA = 'FINCA';
const MODULO_COMPRAS = 'COMPRAS';
const MODULO_PLANTAS = 'PLANTAS';
const MODULO_LOGISTICA_EXPORTACION = 'LOGISTICA_EXPORTACION';
const MODULO_ADMINISTRATIVOS = 'ADMINISTRATIVOS';
const MODULO_CREDITO_FISCAL = 'CREDITO_FISCAL';
const MODULO_REPORTES = 'REPORTES';
const MODULO_ADMINISTRACION = 'ADMINISTRACION';
const MODULO_INVERSIONES = 'INVERSIONES';
const MODULO_DEUDA = 'DEUDA';

class SideBar extends Component {
    state = {
        isFirstSelected: false,
        isSidebarExpanded: false,
    }
    constructor(props) {
        super(props);
    }
    mouseEnter = () => {
        this.setState({ isSidebarExpanded: true })
    }
    mouseLeave = () => {
        this.setState({ isSidebarExpanded: false })
    }

    /**
     * En base al path actual se determina si un item se marca como activo o no.
     * @param idModulo Una de las constantes definidas con el prefijo MODULO_
     * @param path la URL a comparar.
     * @param isItemSelected indica si el item en cuestión se le dió clic o no.
     */
    isItemSelected(idModulo, path, isItemSelected) {
        // Cuando no se ha dado el primer clic en cualquier item del Sidebar, todo el 'expanded' del sidebar es false pero
        // se debe evaluar la URL para comprobar si hace math (y por lo tanto marcarla como activo).
        const isFirstSelected = this.state.isFirstSelected;
        isItemSelected && !isFirstSelected && (this.setState({isFirstSelected: true}));
        let isMatch = false;
        const isSidebarExpanded = this.state.isSidebarExpanded;
        switch (idModulo) {
            case MODULO_PARAMETROS:
                isMatch = path === '#/';
                break;
            case MODULO_VENTAS:
                isMatch = path.includes('/sales/configuration') || path.includes('/sale/resumen')
                break;
            case MODULO_FINCA:
                isMatch = path.includes('/finca/configuration') || path.includes('/finca/summary');
                break;
            case MODULO_COMPRAS:
                isMatch = path.includes('/shopping/configuration') || path.includes('/shopping/summary');
                break;
            case MODULO_PLANTAS:
                isMatch = path.includes('/planta-latex/configuration') || path.includes('/planta-latex/summary');
                break;
            case MODULO_LOGISTICA_EXPORTACION:
                isMatch = path.includes('/logistica/configuration') || path.includes('/logistica/summary');
                break;
            case MODULO_ADMINISTRATIVOS:
                isMatch = path.includes('/administrativos/configuration') || path.includes('/administrativos/resumen')
                break;
            case MODULO_DEUDA:
                isMatch = path.includes('/deuda/configuration') || path.includes('/deuda/resumen')
                break;
            case MODULO_INVERSIONES:
                isMatch = path.includes('/inversiones/configuration') || path.includes('/inversiones/resumen')
                break;
            case MODULO_CREDITO_FISCAL:
                isMatch = path.includes('/credito-fiscal/summary');
                break;
            case MODULO_ADMINISTRACION:
                isMatch = path.includes('/roles') || path.includes('/usuarios') || path.includes('/customers') || path.includes('/ports') || path.includes('/packings')
                || path.includes('/transport')
                break;
            default:
                isMatch = false;
                break;
        }
        // La siguiente operación se sacó de un Mapa de Karnaugh que arroja como resultado:
        //     y = A'B'CD' + A'B'CD + AB'CD' + ABC'D + ABCD' + ABCD
        //     y = A'B'C + ACD' + ABD
        // donde A=isFirstSelected, B=isItemSelected, C=isMatch, D=isSidebarExpanded
        return !isFirstSelected&&!isItemSelected&&isMatch || isFirstSelected&&isMatch&&!isSidebarExpanded || isFirstSelected&&isItemSelected&&isSidebarExpanded;
    }

    render() {
        const { toggleOpen, navToggle, user } = this.props;
        let permisos = {};
        let proveedor = false;
        let proveedor_admin = false
        if (user.rol_usuario) {
            permisos = user.rol_usuario;
        }
        else {
            permisos.roles = true;
            permisos.usuarios = true;
        }
        return (
            <aside className={`main-sidebar ${toggleOpen ? '' : 'open'}`} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <div className="main-navbar">
                    <nav
                        className="align-items-stretch flex-md-nowrap p-0 navbar navbar-light">
                        <a href="/" className="ml-2 navbar-brand" >
                            {/* <img id="main-logo"
                                className="d-inline-block align-top mr-1"
                                src={require('assets/img/entre_rios_isotipo.png')}
                                 alt="Logo" /> */}
                        </a>
                    </nav>
                </div>
                <div className="nav-wrapper">
                    <Accordion className="nav--no-borders flex-column nav">
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_PARAMETROS, location.hash, expanded);
                                                return <NavLink to="/" exact className={`nav-link${isSelected ? ' active' : ''}`} activeClassName={isSelected ? 'active' : ''}>
                                                    <div className='nav-item-ch'>
                                                        <img src={isSelected ? parametros_activo : parametros}
                                                            className="icon-menu" alt="" />
                                                        PARÁMETROS GENERALES
                                                    </div>
                                                </NavLink>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_VENTAS, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? ventas_activo : ventas}
                                                            className="icon-menu" alt="" />
                                                        VENTAS
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_VENTAS, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/sales/summary" exact className="sub-nav-link " activeClassName={'active'}
                                                        isActive={(match, location) => location.pathname.includes('/sales/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/sales/configuration" exact className="sub-nav-link " activeClassName={'active'}
                                                        isActive={(match, location) => location.pathname.includes('/sales/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}

                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_FINCA, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? finca_activo : finca}
                                                            className="icon-menu" alt="" />
                                                        FINCA
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_FINCA, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/finca/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/finca/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/finca/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/finca/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_COMPRAS, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? compras_activo : compras}
                                                            className="icon-menu" alt="" />
                                                        COMPRAS
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_COMPRAS, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/shopping/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/shopping/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/shopping/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/shopping/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_PLANTAS, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? plantas_activo : plantas} className="icon-menu" alt="" />
                                                        PLANTAS
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector} className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_PLANTAS, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <Accordion allowZeroExpanded>
                                                    {/* Submenú para SOLIDOS */}
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <div className='sub-nav-link-inactivo'>
                                                                    <div className="nav-item-ch-sub-1">
                                                                        <img src={elipse} className="elimpse" alt="" />
                                                                        SOLIDOS
                                                                    </div>
                                                                    <div className='nav-item-ch-1'>
                                                                        <img src={isSelected ? vector_activo : vector} className="icon-vector" alt="" />
                                                                    </div>
                                                                </div>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={isSelected ? 'acordion-expanded-1' : ''}>
                                                            {/* Subelementos para Solidos */}
                                                            <div className='sub-nav-item'>
                                                                <NavLink to="/planta-solidos/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                                    isActive={(match, location) => location.pathname.includes('/planta-solidos/summary')}>
                                                                    <div className="sub-nav-item-ch">
                                                                        <img src={elipse_sub} className="elimpse" alt="" />
                                                                        RESUMEN
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                            <NavLink to="/planta-solidos/configuration" className="sub-nav-link"
                                                                isActive={(match, location) => location.pathname.includes('/planta-solidos/configuration')}>

                                                                <div className="sub-nav-item-ch">
                                                                    <img src={elipse_sub} className="elimpse" alt="" />
                                                                    CONFIGURACIÓN
                                                                </div>
                                                            </NavLink>

                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                    {/* Submenú para LATEX */}
                                                    <AccordionItem>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <div className='sub-nav-link-inactivo'>
                                                                    <div className="nav-item-ch-sub-1">
                                                                        <img src={elipse} className="elimpse" alt="" />
                                                                        LATEX
                                                                    </div>
                                                                    <div className='nav-item-ch-1'>
                                                                        <img src={isSelected ? vector_activo : vector} className="icon-vector" alt="" />
                                                                    </div>
                                                                </div>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={isSelected ? 'acordion-expanded-1' : ''}>
                                                            {/* Subelementos para LATEX */}
                                                            <div className='sub-nav-item'>
                                                                <NavLink to="/planta-latex/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                                    isActive={(match, location) => location.pathname.includes('/planta-latex/summary')}>
                                                                    <div className="sub-nav-item-ch">
                                                                        <img src={elipse_sub} className="elimpse" alt="" />
                                                                        RESUMEN
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                            <NavLink to="/planta-latex/configuration" className="sub-nav-link"
                                                                isActive={(match, location) => location.pathname.includes('/planta-latex/configuration')}>

                                                                <div className="sub-nav-item-ch">
                                                                    <img src={elipse_sub} className="elimpse" alt="" />
                                                                    CONFIGURACIÓN
                                                                </div>
                                                            </NavLink>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_LOGISTICA_EXPORTACION, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? logistica_exportacion_activo : logistica_exportacion}
                                                            className="icon-menu" alt="" />
                                                        LOGISTICA Y EXPORTACIÓN
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_LOGISTICA_EXPORTACION, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/logistica/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/logistica/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/logistica/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/logistica/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_ADMINISTRATIVOS, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? administrativos_activo : administrativos}
                                                            className="icon-menu" alt="" />
                                                        ADMINISTRATIVOS
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_ADMINISTRATIVOS, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/administrativos/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/administrativos/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/administrativos/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/administrativos/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_DEUDA, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? deuda_activo : deuda}
                                                            className="icon-menu" alt="" />
                                                        MANEJO DE DEUDA
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_DEUDA, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/deuda/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/deuda/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/deuda/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/deuda/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_INVERSIONES, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? inversiones_activo : inversiones}
                                                            className="icon-menu" alt="" />
                                                        INVERSIONES
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_INVERSIONES, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/inversiones/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/inversiones/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/inversiones/configuration" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/inversiones/configuration')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            CONFIGURACIÓN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_CREDITO_FISCAL, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? credito_fiscal_activo : credito_fiscal}
                                                            className="icon-menu" alt="" />
                                                        CRÉDITO FISCAL
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_CREDITO_FISCAL, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/credito-fiscal/summary" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/credito-fiscal/summary')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESUMEN
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        <div className="nav-item">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <AccordionItemState>
                                            {({ expanded }) => {
                                                const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                                return <div className={`nav-link ${isSelected ? 'active' : ''}`}>
                                                    <div className="nav-item-ch">
                                                        <img src={isSelected ? reportes_activo : reportes}
                                                            className="icon-menu" alt="" />
                                                        REPORTES
                                                    </div>
                                                    <div className='nav-item-ch-1'>
                                                        <img src={isSelected ? vector_activo : vector}
                                                            className="icon-vector" alt="" />
                                                    </div>
                                                </div>
                                            }}
                                        </AccordionItemState>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemState>
                                    {({ expanded }) => {
                                        const isSelected = this.isItemSelected(MODULO_REPORTES, location.hash, expanded);
                                        return isSelected &&
                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                <div className="br-top"></div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/pilas" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/pila')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            FLUJO DE CAJA
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/almacenes" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/almacen')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            BALANCE
                                                        </div>

                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            ESTADO DE RESULTADOS
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            RESULTADOS DE OPERACIÓN
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            COSTO DE COMPRA M.P.A.
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            COSTO DE VENTA P.
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                                        <AccordionItem>
                                                            <AccordionItemHeading>
                                                                <AccordionItemButton>
                                                                <div className='sub-nav-link-inactivo'>
                                                                    <div className="nav-item-ch-sub-1">
                                                                        <img src={elipse} className="elimpse" alt="" />
                                                                        GRUPO DE REORTES E.V.
                                                                    </div>
                                                                    <div className='nav-item-ch-1'>
                                                                        <img src={isSelected ? vector_activo : vector} className="icon-vector" alt="" />
                                                                    </div>
                                                                </div>
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel className={isSelected ? 'acordion-expanded-1' : ''}>
                                                            {/* Subelementos para LATEX */}
                                                            <div className='sub-nav-item'>
                                                                <NavLink to="/us" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                                    isActive={(match, location) => location.pathname.includes('/pila')}>
                                                                    <div className="sub-nav-item-ch">
                                                                        <img src={elipse_sub} className="elimpse" alt="" />
                                                                        VOLUMENES DE VENTA
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                            <NavLink to="/latex/resumen" className="sub-nav-link"
                                                                isActive={(match, location) => location.pathname.includes('/pila')}>

                                                                <div className="sub-nav-item-ch">
                                                                    <img src={elipse_sub} className="elimpse" alt="" />
                                                                    MARGEN POR K.P.F.E.R.
                                                                </div>
                                                            </NavLink>
                                                        </AccordionItemPanel>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            COMPARATIVOS ANUALES
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            PRESUPUESTO A.A
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="sub-nav-item">
                                                    <NavLink to="/canal" exact className="sub-nav-link " activeClassName={isSelected ? 'active' : ''}
                                                        isActive={(match, location) => location.pathname.includes('/canal')}>
                                                        <div className="sub-nav-item-ch">
                                                            <img src={elipse} className="elimpse" alt="" />
                                                            PRESUPUESTO A.E.P.
                                                        </div>
                                                    </NavLink>
                                                </div>
                                                <div className="br-top"></div>
                                            </AccordionItemPanel>
                                    }}
                                </AccordionItemState>
                            </AccordionItem>
                        </div>
                        {((permisos.roles || permisos.usuarios)) && (
                            <div className="nav-item">
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <AccordionItemState>
                                                {({ expanded }) => {
                                                    const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                                    return <div className={`nav-link ${isSelected ? 'active' : ''}`} >
                                                        <div className="nav-item-ch">
                                                            <img src={isSelected ? administracion_activo : administracion}
                                                                className="icon-menu" alt="" />
                                                            ADMINISTRACIÓN
                                                        </div>
                                                        <div className='nav-item-ch-1'>
                                                            <img src={isSelected ? vector_activo : vector}
                                                                className="icon-vector" alt="" />
                                                        </div>
                                                    </div>
                                                }}
                                            </AccordionItemState>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            const isSelected = this.isItemSelected(MODULO_ADMINISTRACION, location.hash, expanded);
                                            return isSelected &&
                                                <AccordionItemPanel className={isSelected ? 'acordion-expanded' : ''}>
                                                    <div className="br-top"></div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/roles" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/roles')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                ROLES
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/usuarios" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/usuarios')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                USUARIOS
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/customers" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/customers')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                CLIENTES
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/ports" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/ports')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                PUERTOS DE SALIDA
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/packings" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/packings')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                EMPAQUES
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="sub-nav-item">
                                                        <NavLink to="/transport" exact className="sub-nav-link " activeClassName={'active'}
                                                            isActive={(match, location) => location.pathname.includes('/transport')}>
                                                            <div className="sub-nav-item-ch">
                                                                <img src={elipse} className="elimpse" alt="" />
                                                                TIPOS DE TRANSPORTES
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                    <div className="br-top"></div>
                                                </AccordionItemPanel>
                                        }}
                                    </AccordionItemState>
                                </AccordionItem>
                            </div>
                        )}
                    </Accordion>
                </div>
            </aside>
        )
    }
}

export default SideBar;