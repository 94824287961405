import React, { Component } from 'react';
import equal from 'fast-deep-equal';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled, renderNumberPercentage, renderPercentageDisabled, renderNumberKGDisabled, renderNumberKG, renderNumberDisabled } from '../../../../../Utils/renderField/renderField';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { months } from '../../../../../Utils/months';
class TableRow extends Component {
    componentDidMount() {
        this.handleInputChange();
    }
    componentDidUpdate(prevProps) {
        // Comprueba si las propiedades relevantes han cambiado
        if (!equal(prevProps.propiedades, this.props.propiedades)) {
            // Si las propiedades han cambiado, llama a handleInputChange
            this.handleInputChange();
        }
    }

    handleInputChange() {
        const { propiedades, change } = this.props;
        const { data, form_values } = propiedades;
        const { sales_data_latex, parameters_data, kg_proyectados } = data;
        const drc_values = form_values ? form_values.values.latex_table[2] : [];
        let latex_table = [];

        const {
            var_yield,
            var_skim,
            var_clot_wash,
            var_clot_traps,
            var_clot_centrifuged,
            produces_latex,
            var_lost,
            purchase_price_table,
            prima_for_production_nf,
            prima_for_production_nf_porcentage
        } = parameters_data;

        const kg_proyectados_data = kg_proyectados
        const sales_data_form = sales_data_latex
        const priceWithIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (CON IVA) LATEX') : [];
        const priceWithoutIva = purchase_price_table ? purchase_price_table.generic_row.find(item => item.name === 'PRECIO (SIN IVA) LATEX') : [];
        // Validacion de prima fn
        const prima_for_production_nf_data = prima_for_production_nf_porcentage > 0 ? prima_for_production_nf : 0;
        const meses = months;


        function convertToDecimalPercentage(value) {
            return value / 100;
        }

        var yeild = convertToDecimalPercentage(var_yield);

        const calculate = (data, factor) => {
            return meses.reduce((obj, mes) => {
                obj[mes] = (data[mes] || 0) * factor;
                return obj;
            }, {});
        }

        const const_shopping_latex = 0.9
        const calculatedProductionFincaLatex = calculate(kg_proyectados_data, const_shopping_latex * yeild);

        const CalculateEquivalentKilos = (sales_data_pros, calculatedProductionFincaLatex, params) => {
            if (yeild === 0) {
                throw new Error('yeild cannot be 0');
            }
            return meses.reduce((obj, mes) => {
                const calculatedLatexPerYeild = produces_latex ? calculatedProductionFincaLatex[mes] / yeild : null;
                obj[mes] = (sales_data_pros[mes] - calculatedLatexPerYeild) * params;
                return obj;
            }, {});
        }

        let porcentageParams = 1 + (
            convertToDecimalPercentage(var_clot_wash) +
            convertToDecimalPercentage(var_clot_traps) +
            convertToDecimalPercentage(var_clot_centrifuged) +
            convertToDecimalPercentage(var_lost)
        );

        let dataEquivalentKilos = CalculateEquivalentKilos(sales_data_form, calculatedProductionFincaLatex, porcentageParams);

        const CalculateWetKilos = (drcData, dataEquivalentKilos) => {
            return meses.reduce((obj, mes) => {
                if (drcData[mes] && dataEquivalentKilos[mes]) {
                    obj[mes] = dataEquivalentKilos[mes] / convertToDecimalPercentage(drcData[mes]);
                }
                return obj;
            }, {});
        }

        const CalculatePriceWithoutIvaNF = (priceWithoutIva, prima_for_production_nf_data) => {
            return meses.reduce((obj, mes) => {
                if (priceWithoutIva[mes]) {
                    obj[mes] = prima_for_production_nf_data !== 0 ? priceWithoutIva[mes] + prima_for_production_nf_data : priceWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const PriceWithoutIvaNF = CalculatePriceWithoutIvaNF(priceWithoutIva, prima_for_production_nf_data)

        const monthlyDebit = 1.12
        const CalculatePriceWithIvaNF = (PriceWithoutIvaNF, monthlyDebit) => {
            return meses.reduce((obj, mes) => {
                if (PriceWithoutIvaNF[mes]) {
                    obj[mes] = PriceWithoutIvaNF[mes] * monthlyDebit;
                }
                return obj;
            }, {});
        }
        const priceWithIvaNF = CalculatePriceWithIvaNF(PriceWithoutIvaNF, monthlyDebit)
        // En esta funcion puede encontrar un 1 esta es una constante de formula
        const CalculateNetPayForPurchasesWithoutIva = (dataEquivalentKilos, priceWithoutIva, PriceWithoutIvaNF, prima_for_production_nf_porcentage) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithoutIva[mes] && PriceWithoutIvaNF[mes]) {
                    obj[mes] = prima_for_production_nf_porcentage !== 0
                        ? (dataEquivalentKilos[mes] * (1 - convertToDecimalPercentage(prima_for_production_nf_porcentage)) * priceWithoutIva[mes]) + (dataEquivalentKilos[mes] * convertToDecimalPercentage(prima_for_production_nf_porcentage) * PriceWithoutIvaNF[mes])
                        : (dataEquivalentKilos[mes] * 1 * priceWithoutIva[mes]);
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithoutIva = CalculateNetPayForPurchasesWithoutIva(dataEquivalentKilos, priceWithoutIva, PriceWithoutIvaNF, prima_for_production_nf_porcentage)

        const CalculateNetPayForPurchasesWithIva = (dataEquivalentKilos, priceWithIva, priceWithIvaNF, prima_for_production_nf_porcentage) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && priceWithIva[mes] && priceWithIvaNF[mes]) {
                    obj[mes] = prima_for_production_nf_porcentage !== 0
                        ? (dataEquivalentKilos[mes] * (1 - convertToDecimalPercentage(prima_for_production_nf_porcentage)) * priceWithIva[mes]) + (dataEquivalentKilos[mes] * convertToDecimalPercentage(prima_for_production_nf_porcentage) * priceWithIvaNF[mes])
                        : (dataEquivalentKilos[mes] * 1 * priceWithIva[mes]);
                }
                return obj;
            }, {});
        }
        const netPayForPurchasesWithIva = CalculateNetPayForPurchasesWithIva(dataEquivalentKilos, priceWithIva, priceWithIvaNF, prima_for_production_nf_porcentage)

        const CalculateAmountofIvaPaid = (netPayForPurchasesWithoutIva, netPayForPurchasesWithIva) => {
            return meses.reduce((obj, mes) => {
                if (netPayForPurchasesWithoutIva[mes] && netPayForPurchasesWithIva[mes]) {
                    obj[mes] = netPayForPurchasesWithIva[mes] - netPayForPurchasesWithoutIva[mes];
                }
                return obj;
            }, {});
        }
        const amountofIvaPaid = CalculateAmountofIvaPaid(netPayForPurchasesWithoutIva, netPayForPurchasesWithIva)

        const CalculatePricePerKiloCombinedWithIva = (dataEquivalentKilos, netPayForPurchasesWithIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && netPayForPurchasesWithIva[mes]) {
                    obj[mes] = netPayForPurchasesWithIva[mes] / dataEquivalentKilos[mes];
                }
                return obj;
            }, {});
        }
        const pricePerKiloCombinedWithIva = CalculatePricePerKiloCombinedWithIva(dataEquivalentKilos, netPayForPurchasesWithIva)

        const CalculatePricePerKiloCombinedWithoutIva = (dataEquivalentKilos, netPayForPurchasesWithoutIva) => {
            return meses.reduce((obj, mes) => {
                if (dataEquivalentKilos[mes] && netPayForPurchasesWithoutIva[mes]) {
                    obj[mes] = netPayForPurchasesWithoutIva[mes] / dataEquivalentKilos[mes];
                }
                return obj;
            }, {});
        }
        const pricePerKiloCombinedWithoutIva = CalculatePricePerKiloCombinedWithoutIva(dataEquivalentKilos, netPayForPurchasesWithoutIva)


        latex_table = [
            { name: 'Compra de látex (costo neto)', data: netPayForPurchasesWithoutIva },
            { name: 'kILOS hUMEDOS', total: 0, data: CalculateWetKilos(drc_values, dataEquivalentKilos), is_kg: true },
            { name: 'drc promedio general', data: drc_values, is_drc: true },
            { name: 'kilos secos equivalentes', data: dataEquivalentKilos, is_kg: true },
            { name: 'precio por kilo seco (sin iva)', data: priceWithoutIva },
            { name: 'precio por kilo seco (con iva)', data: priceWithIva },
            { name: 'Precio PRIMA por kilo seco NF (SIN IVA)', data: PriceWithoutIvaNF },
            { name: 'Precio PRIMA por kilo seco NF (CON IVA)', data: priceWithIvaNF },
            { name: 'precio por kilo seco (sin iva) combinado', data: pricePerKiloCombinedWithoutIva },
            { name: 'precio por kilo seco (con iva) combinado', data: pricePerKiloCombinedWithIva },
            { name: 'PAGO NETO POR COMPRAS SIN IVA', data: netPayForPurchasesWithoutIva },
            { name: 'PAGO BRUTO POR OCMPRAS CON IVA', data: netPayForPurchasesWithIva },
            { name: 'IMPORTE DEL IVA PAGADO', data: amountofIvaPaid, is_iva_credit: true },
        ];

        latex_table.forEach(item => {
            let total = 0;
            let count = 0;
            for (let mes in item.data) {
                total += item.data[mes];
                count++;
            }
            // Lista de nombres para los que se debe calcular el promedio
            const namesForAverage = [
                'precio por kilo seco (sin iva)',
                'precio por kilo seco (con iva)',
                'Precio PRIMA por kilo seco NF (SIN IVA)',
                'Precio PRIMA por kilo seco NF (CON IVA)',
                'precio por kilo seco (sin iva) combinado',
                'precio por kilo seco (con iva) combinado'
            ];
            if (namesForAverage.includes(item.name)) {
                item.total = total / count;
            } else {
                item.total = total;
            }
            Object.assign(item, item.data);
        });

        change('latex_table', latex_table);
    }

    render() {
        const { index, system, fields, step } = this.props;

        return (
            <React.Fragment key={index}>
                <tr
                    key={`system-${index}`}
                    style={{
                        ...(index === 10 || index === 11 || index === 12) && { backgroundColor: "#F0FFC7" },
                        borderBottom: (index === 0 || index === 9 || index === 10 || index === 11) ? "1px solid #035B2F" : "none"
                    }}
                >
                    <td className="text-center sin-borde-top " style={{ width: "15%", }}>
                        <Field
                            name={`${system}.name`}
                            type="text"
                            component={renderFieldDisabled}
                            isTextarea={false}
                            applyStyles={false}
                            className="form-control"
                            placeholder='Nuevo'
                        />
                    </td>
                    <td style={{ width: "6.54%" }}>
                        <Field
                            name={`${system}.total`}
                            type="text"
                            disabled={true}
                            component={fields.get(index).is_drc ? renderPercentageDisabled : fields.get(index).is_kg ? renderNumberDisabled : renderDollarDisabled}
                            placeholder='0.00 %'
                            disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            className="form-control"
                        />
                    </td>
                    {months.map((month) => (
                        <td key={month} style={{ width: "6.54%" }}>
                            <Field
                                name={`${system}.${month}`}
                                type="text"
                                component={
                                    step === 1
                                        ?
                                        (fields.get(index).is_drc ? renderNumberPercentage : fields.get(index).is_kg ? renderNumberDisabled : renderDollarDisabled)
                                        :
                                        (fields.get(index).is_drc ? renderPercentageDisabled : fields.get(index).is_kg ? renderNumberDisabled : renderDollarDisabled)

                                }
                                className="form-control"
                                placeholder={fields.get(index).is_drc ? '0.00 %' : '$ 0.00'}
                                disabledStyle={index === 0 ? { fontWeight: 'bold' } : {}}
                            />
                        </td>
                    ))}
                </tr>
            </React.Fragment>
        );
    }
}

class GenericTable extends Component {
    render() {
        const { fields, change, step, propiedades } = this.props;
        return (
            <div className=" col-sm-12 form-group np-r p-0">
                <div className='scroll-container'>
                    <div className='scroll-content-shopping'>
                        <div className="react-bs-container-header table-header-wrapper">
                            <table className="table mb-0 text-center">
                                <thead>
                                    <tr>
                                        <th style={{ width: "15%" }}></th>
                                        <th style={{ width: "6.53%" }}>TOTAL ANUAL</th>
                                        {Array.from({ length: 12 }, (_, i) => (
                                            <th key={i} style={{ width: "6.53%" }}>{i + 1}</th>
                                        ))}
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="tabla-con-borde">
                            <table className="table table-sm  m-0 table-striped">
                                <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                    {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} propiedades={propiedades} step={step} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

class ShoppingLatexTable extends Component {
    render() {
        const { change, step } = this.props;
        return (
            <div>
                <FieldArray
                    name='latex_table'
                    change={change}
                    component={GenericTable}
                    step={step}
                    propiedades={this.props}
                />
            </div>
        );
    }
}

const formName = 'ShoppingForm';

const selector = formValueSelector(formName);

const mapStateToProps = (state) => {
    const categoryBudget = selector(state, 'generic_row') || [];
    const form_values = state.form.ShoppingForm || {};
    const allValues = categoryBudget.map(item => item);

    return { form_values, allValues };
};

export default connect(mapStateToProps)(ShoppingLatexTable);
