import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/logistics_and_export/logistics_and_export';
import LogisticsAndExportListSummary from "./LogisticsAndExport";


const ms2p = (state) => {
  return {
    ...state.logistics_and_export,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(LogisticsAndExportListSummary);
