import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import VatDragModal from './Modal/VatDragModal';

class TaxCreditListSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }


    componentDidMount() {
        this.props.read();
    }


    openModal = () => {

        this.setState({ open: true });

    };

    closeModal = () => {
        this.setState({ open: false });

    };
    actualizarModal = (data) => {
        this.props.editarModal(data.id, data)
        this.closeModal()
    }

    openAndConsultModal = () =>{
        this.props.getVatDragConfig();
        this.openModal()
    }


    render() {
        const { data, loader, readShow } = this.props;

        return (
            <div className="mb-4 col-12">
                <Card
                    noShadow
                    icon="Side_activos/creditofiscal.svg"
                    titulo="CREDITO FISCAL"
                    subtitulo="RESUMEN"
                >
                    <div className="row col-md-12 p-0 mb-4 mt-4">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">CREDITO FISCAL</h4>
                        </div>
                        <div className="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                            <button className="btn btn-light btn-sm justify-content-end agregar-tab-generica" onClick={() => this.openAndConsultModal()} >CONFIGURACIÓN</button>
                        </div>
                    </div>
                    <Listado data={data} loader={loader} readShow={readShow} {...this.props} />
                </Card>

                <VatDragModal
                    onSubmit={this.actualizarModal}
                    open={this.state.open}
                    dollarExchange={data.dollar_exchange}
                    closeModal={this.closeModal}
                    change={this.props.change}
                    {...this.props}
                />
            </div>
        );
    }
}

export default TaxCreditListSummary;
