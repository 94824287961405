import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import ShoppingForm from './ShoppingForm';

export default class ShoppingShow extends Component {

    componentDidMount() {
        this.props.getDollar()
        this.props.read()
    }

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {

        const { loader, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    <ShoppingForm
                        item={item}
                        permisos={this.getPermissions}
                        {...this.props}
                    />
                </LoadMask>
            </div>
        )
    }
}

