import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderField, renderNumberPercentage, renderFieldRadio, renderNumber, renderDatePicker, AsyncSelectField, renderFieldDisabled } from '../../../../../Utils/renderField/renderField';
import CardEmpty from '../../../../../Utils/Cards/CardEmpty';
import { api } from "api";



const TableRow = ({ system, index }) => {

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top" style={{ width: "50%" }}>
                    <Field
                        name={`${system}.month`}
                        type="text"
                        component={renderFieldDisabled}
                        isTextarea
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td style={{ width: '10%' }}></td>
                <td className="text-center sin-borde-top" style={{ width: "30%" }}>
                    <Field
                        name={`${system}.value`}
                        type="text"
                        component={renderDollar}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td style={{ width: '10%' }}></td>
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields }) => {

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0 text-center">
                    <thead>
                        <tr>
                            <th style={{ width: "50%" }}>MES</th>
                            <th style={{ width: "50%" }}>IVA</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody style={{ backgroundColor: "#F0FFC7" }}>
                        {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} />)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
const TableRowGeneral = ({ system, index }) => {

    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}>
                <td className="text-center sin-borde-top" style={{ width: "33%" }}>
                    <Field
                        name={`${system}.year`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className="text-center sin-borde-top" style={{ width: "33%" }}>
                    <Field
                        name={`${system}.month`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                <td className="text-center sin-borde-top" style={{ width: "33%" }}>
                    <Field
                        name={`${system}.value`}
                        type="text"
                        component={renderDollar}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
            </tr>
        </React.Fragment>
    );
};

const GenericTableGeneral = ({ fields }) => {

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0 text-center">
                    <thead>
                        <tr>
                            <th style={{ width: "33%" }}>AÑO</th>
                            <th style={{ width: "33%" }}>ACUMULADO AL MES</th>
                            <th style={{ width: "33%" }}>IVA</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody style={{ backgroundColor: "#F0FFC7" }}>
                        {fields.map((system, index) => <TableRowGeneral key={index} system={system} index={index} fields={fields} />)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}


const ModalForm = () => {

    return (
        <div className="col-12 p-0 mt-2">
            <div className="d-flex flex-column align-items-center mb-4">
                <h6 className="text-center">RÉGIMEN ESPECIAL</h6>
                <small className="text-center mb-3">
                    FAVOR INGRESAR LOS CUATRO MESES ANTERIORES AL AÑO DEL PRESUPUESTO
                </small>
                <FieldArray
                    name={`special_regime`}
                    component={GenericTable}
                />
            </div>

            <div className="d-flex flex-column align-items-center mb-4">
                <h6 className="text-center">RÉGIMEN GENERAL</h6>
                <small className="text-center mb-3">
                    FAVOR INGRESAR EL CRÉDITO FISCAL ACUMULATIVO DE LOS AÑOS ANTERIORES AL PRESUPUESTO.
                </small>
                <FieldArray
                    name={`general_regime`}
                    component={GenericTableGeneral}
                />
            </div>

        </div>
    );
};

export default ModalForm;
