import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/admin/transport';
import {getMe} from "../../../../../../redux/modules/cuenta/login";
import CreateTransport from './CreateTransport';

const mstp = state => {
    return {...state.transports}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(CreateTransport)
