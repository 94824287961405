import React, { Component } from 'react';
import Card from "Utils/Cards/Card";
import List from "./SalesList";
import ListSubProduction from './SalesSubProduction';
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import { Link } from 'react-router-dom';
import ProductionExportable from './Table/ProductionExportableLatex';
import SalesDivers from './Table/SalesDivers';
export default class SalesList extends Component {
    state = {
        sublista: false,
    }

    componentDidMount() {
        this.setState({ mounted: true });
        this.cargandoData();
    }

    componentDidUpdate(prevProps) {
        if (this.state.mounted && this.props.match.params.id !== prevProps.match.params.id) {
            this.cargandoData();
        }
    }

    cargandoData() {
        const { match, sub_listar, listar, page } = this.props;

        if (match.params.id) {
            sub_listar(match.params.id);
            this.setState({ sublista: true });
        } else {
            listar(page);
            this.setState({ sublista: false });
        }
    }
    actualizar = (data) => {
        this.props.editar_divers(data.id, data);
    };

    render() {
        const { setTab, tab, produce_finca } = this.props;
        const currentYear = new Date().getFullYear();
        const nextYear = currentYear + 1;

        return (
            <div className="mb-4 col-12">
                <Card noShadow icon="Side_activos/ventas.svg" titulo="PRESUPUESTO DE VENTAS" subtitulo="CONFIGURACIÓN" noBorder={this.state.sublista ? false : true}>
                    {this.state.sublista ? (
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6">
                                <h4 className="mr-2">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                            </div>
                        </div>
                    ) : (
                        <Tabs activeKey={tab} onChange={tab => setTab(tab)}>
                            <TabPane tab={`Año ${nextYear}`} key="EN_CURSO">
                                <CardEmpty noShadow>
                                    <div className="row col-md-12 p-0 mt-2 mb-4">
                                        <div className="col-md-6">
                                            <h4 className="mr-2 titulo_principal">PRONOSTICO DE VENTAS CONSOLIDADO</h4>
                                        </div>
                                    </div>
                                    <div className='mb-4'>
                                        <List {...this.props} />
                                    </div>
                                </CardEmpty>
                                <br/>
                                <CardEmpty noShadow >
                                    <div className="col-md-3 p-0 mt-2">
                                        <div className="md-12">
                                            <h6 className="mb-4"><strong>VENTAS VARIAS</strong></h6>
                                        </div>
                                    </div>
                                    <SalesDivers
                                        {...this.props}
                                        onSubmit={this.actualizar}
                                    />
                                </CardEmpty>
                                <br/>
                                <CardEmpty noShadow >
                                    <div className="col-md-3 p-0 mt-2">
                                        <div className="md-12">
                                            <h6 className="mb-4"><strong>PRODUCCIÓN EXPORTABLE</strong></h6>
                                        </div>
                                    </div>
                                    <ProductionExportable
                                        {...this.props}
                                    />
                                </CardEmpty>

                            </TabPane>
                        </Tabs>
                    )}
                    {this.state.sublista && <ListSubProduction {...this.props} />}
                    {this.state.sublista && (
                        <div className='buttons-box'>
                            <Link className="btn btn-outline-dark m-4 btn-modal" type="button" to="/sales/configuration">REGRESAR</Link>
                        </div>
                    )}
                </Card>

            </div>
        );
    }
}