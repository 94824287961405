import React, { Component } from 'react'
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './AdministrativosForm';
import AdministrativosForm from './AdministrativosForm';

export default class AdministrativosCrear extends Component {

    state = {
        editar: false,
        open: false,
    };
    componentDidMount() {
        this.props.getDollar()
        if (this.props.match.params.id) {
            this.props.read(this.props.match.params.id);
            this.setState({ editar: true });
        }
        
    }
    openModalCategory = () => {

        this.setState({ open: true });

    };
    closeModalCategory = () => {
        this.setState({ open: false });

    };

    actualizar = (data) => {
        this.props.editar(this.props.match.params.id, data);
    };

    getPermissions = () => {
        const { getMe } = this.props;
        getMe();
    }

    render() {
        const { crear, editar } = this.props;
        const { updateData, loader, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <LoadMask loading={loader} blur>
                    {
                        this.state.editar ?
                            <AdministrativosForm
                                onSubmit={this.actualizar}
                                updateData={updateData}
                                item={item}
                                editando={true}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                {...this.props}
                            />
                            :
                            <Form
                                onSubmit={crear}
                                permisos={this.getPermissions}
                                openModal={this.openModalCategory}
                                closeModal={this.closeModalCategory}
                                open={this.state.open}
                                item={item}
                            />
                    }
                </LoadMask>
            </div>
        )
    }
}

