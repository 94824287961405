import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderFieldDisabled, renderDollarDisabled } from '../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../Utils/months';


const TYPE_OF_ROW_MAPPING = {
    TOTAL: 5,
    DESCUENTO: 10,
}
const TableRow = ({ system, index, fields, change }) => {
    const _months = months

    const calculateTotalForRow = () => {
        const systemData = fields.get(index);
        if (!systemData) return 0;

        let totalForRow = _months.reduce((accumulatedTotal, month) => {
            const value = parseFloat(systemData[month]) || 0;
            accumulatedTotal += value;
            return accumulatedTotal;
        }, 0);
        return totalForRow;
    };


    const Total = [TYPE_OF_ROW_MAPPING.TOTAL].includes(fields.get(index).type_of_row);
    const Descuento = [TYPE_OF_ROW_MAPPING.DESCUENTO].includes(fields.get(index).type_of_row);



    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}
                style={Total ? { backgroundColor: "#F0FFC7" } :
                    Descuento ? { backgroundColor: "#F0FFC7", border: "1px solid #000000" } : {}}
            >
                <td className="text-center sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={renderDollarDisabled}
                            className="form-control"
                            placeholder='$ 0.00'
                        />
                    </td>
                ))}
                <td style={{ width: "6.9%" }} className="custom-cell text-center">

                    {
                        new Intl.NumberFormat('es-US', { style: 'currency', currency: 'USD' }).format(calculateTotalForRow())
                    }

                </td>
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, change }) => {
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>MES</th>
                                    {columnas.map(columna => columna)}
                                    <th style={{ width: "6.9%" }}>TOTAL</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SalesAdvanceTable = ({ fieldName, debtManagement }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                debtManagement={debtManagement}
            />
        </div>
    );
}

const formName = 'DebtManagementForm';

const mapStateToProps = (state) => ({
    debtManagement: formValueSelector(formName)(state, 'debt_management_budget_detai') || [],
});

export default connect(mapStateToProps)(SalesAdvanceTable);