import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { renderDollar, renderFieldDisabled, renderDollarDisabled } from '../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { months } from '../../../../Utils/months';


const TYPE_OF_ROW_MAPPING = {
    TOTAL_INVESTMENTS: 20,
    INITIAL_INVESTMENT: 21,
    RETURN_ON_INVESTMENT: 22,
    INVESTMENT_BALANCE: 23,
    INVESTMENT_INCREASE: 24,
}
const TableRow = ({ system, index, fields, change }) => {

    const isEditRow = [TYPE_OF_ROW_MAPPING.RETURN_ON_INVESTMENT, TYPE_OF_ROW_MAPPING.INVESTMENT_INCREASE].includes(fields.get(index).type_of_row);
    return (
        <React.Fragment key={index}>
            <tr key={`system-${index}`}
                style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
            >
                <td className="text-center sin-borde-top" style={{ width: "10%" }}>
                    <Field
                        name={`${system}.name`}
                        type="text"
                        component={renderFieldDisabled}
                        className="form-control"
                        placeholder='Nuevo'
                    />
                </td>
                {months.map((month) => (
                    <td key={month} style={{ width: "6.9%" }}>
                        <Field
                            name={`${system}.${month}`}
                            type="text"
                            component={isEditRow ? renderDollar : renderDollarDisabled}
                            className="form-control"
                            placeholder='$ 0.00'
                            style={isEditRow ? { backgroundColor: "#F0FFC7" } : {}}
                        />
                    </td>
                ))}
            </tr>
        </React.Fragment>
    );
};

const GenericTable = ({ fields, change }) => {

    const calculateTotalForMonth = () => {
        const allFields = fields.getAll() || []
        const authorizedRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.TOTAL_INVESTMENTS);
        const initialBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INITIAL_INVESTMENT);
        const repaymentsRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.RETURN_ON_INVESTMENT);
        const finalBalanceRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INVESTMENT_BALANCE);
        const increaseRow = allFields.find(row => row.type_of_row === TYPE_OF_ROW_MAPPING.INVESTMENT_INCREASE);

        // Iterar sobre los meses para calcular los valores
        months.forEach((month, index) => {
            if (authorizedRow && initialBalanceRow && repaymentsRow && finalBalanceRow && increaseRow) {
                const authorized = parseFloat(authorizedRow[month]) || 0;
                const initialBalance = parseFloat(initialBalanceRow[month]) || 0;
                const repayments = parseFloat(repaymentsRow[month]) || 0;
                const increase = parseFloat(increaseRow[month]) || 0;

                const finalBalance = initialBalance - repayments;

                // Si es enero, el saldo inicial es el ingresado por el usuario.
                if (month !== 'enero') {
                    const previousMonth = months[index - 1];
                    initialBalanceRow[month] = (parseFloat(finalBalanceRow[previousMonth]) || 0) + (parseFloat(increaseRow[previousMonth]) || 0);
                }

                finalBalanceRow[month] = finalBalance;
            }
        });

    };
    const columnas = Array.from({ length: 12 }, (_, index) => (
        <th key={index} style={{ width: "6.9%" }}>{index + 1}</th>
    ));

    months.map((month, idx) => (
        calculateTotalForMonth()
    ));

    return (
        <div className=" col-sm-12 form-group np-r p-0">
            <div className='scroll-container'>
                <div className='scroll-content-sales'>
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0 text-center">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>MES</th>
                                    {columnas.map(columna => columna)}
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde">
                        <table className="table table-sm  m-0 table-striped">
                            <tbody style={{ backgroundColor: "#F0FFC7" }}>
                                {fields.map((system, index) => <TableRow key={index} system={system} index={index} fields={fields} change={change} />)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const AdministrativosTableBank = ({ fieldName, debtManagement }) => {
    return (
        <div>
            <FieldArray
                name={`${fieldName}`}
                component={GenericTable}
                debtManagement={debtManagement}
            />
        </div>
    );
}

const formName = 'DebtManagementForm';

const mapStateToProps = (state) => ({
    debtManagement: formValueSelector(formName)(state, 'debt_management_budget_detai') || [],
});

export default connect(mapStateToProps)(AdministrativosTableBank);