import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import Listado from './Listado';
import ConnectedCategoriaModal from './Modal/CategoriaModal';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import KgSecos from './KgSecos';

class PlantaLatexList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            initialValues: {}
        };
    }

    componentDidMount() {
        this.props.read();
    }

    openModal = () => {
        this.setState({
            open: true,
            initialValues: {}
        });
    };

    closeModal = () => {
        this.setState({
            open: false,
            initialValues: {}
        });
        this.props.initializeKGForm(this.props.data)
    };

    handleAddCategory = (data) => {
        const { editar, crear, reset } = this.props;

        if (data.id) {
            editar(data.id, data);
        } else {
            crear(data);
        }

        this.closeModal();
        reset();
    };

    actualizar = (data) => {
        this.props.editar(data.data_kg[0].id, data);
    };

    render() {
        const { data, loader, readEdit, reset, data_modal } = this.props;
        const { results } = data_modal;
        const { open, initialValues } = this.state;

        return (
            <div className="mb-4 col-12">
                <CardFormNoBorder
                    icon="Side_activos/Admin.svg"
                    titulo="PLANTA LATEX"
                    subtitulo="CONFIGURACIÓN"
                    col="12"
                    noShadow
                >
                </CardFormNoBorder>
                <CardEmpty noShadow isMarginButtom>
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h6 className="mb-4"><strong>KILOGRAMOS SECOS</strong></h6>
                        </div>
                    </div>
                    <div className='mb-4'>
                        {!open &&
                            <KgSecos
                                onSubmit={this.actualizar}
                                {...this.props}
                            />}
                    </div>
                </CardEmpty>
                <CardEmpty noShadow>
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">PLANTAS LATEX</h4>
                        </div>
                    </div>
                    <Listado
                        data={data}
                        loader={loader}
                        openModal={this.openModal}
                        readEdit={readEdit}
                        reset={reset}
                        {...this.props}
                    />
                </CardEmpty>

                <ConnectedCategoriaModal
                    open={open}
                    dollarExchange={data.dollar_exchange}
                    closeModal={this.closeModal}
                    handleAddCategory={this.handleAddCategory}
                    initialValues={results ? results : initialValues}
                    change={this.props.change}
                    {...this.props}
                />
            </div>
        );
    }
}

export default PlantaLatexList;
