const validate = values => {
    const errors = {};
    const message = 'Campo requerido';
    const messageInvalid = 'Valor no válido';
    const messageMax100 = 'No debe ser mayor a 100%';
    const messageNoNegatives = 'No debe ser negativo';
    const messageIvaMismatch = 'El IVA sujeto a retención debe ser la suma de exportado y contribuyentes especiales';
    const messageIvaNotEqualTo100 = 'La suma de IVA sujeto a retención y no retenido debe ser 100%';

    // Asegúrate de que el objeto parameter_tax_credit exista antes de acceder a sus propiedades
    const taxCredit = values.parameter_tax_credit || {};

    // ** Validaciones estándar de campos obligatorios **
    const requiredFields = [
        'dollar_exchange', 'mar_obj_pon_latex', 'mar_obj_pon_solido', 'mar_obj_latex', 'mar_obj_solido',
        'product_latex_bolsa', 'product_solido_bolsa', 'price_stock_exchange_latex', 'price_stock_exchange_solido',
        'fac_special', 'purchase_suppliers', 'export_sales', 'local_sales', 'var_yield', 'var_skim', 'var_lost',
        'var_clot_wash', 'var_clot_traps', 'var_clot_centrifuged', 'var_margin_latex', 'var_margin_solidos', 
        'var_margin_skim', 'var_max_ability_tanks', 'var_cant_rubber_solido', 'var_skim_mix_solido', 'var_dif_paymen_vs_prod'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = message;
        }
    });

    // ** Validaciones para los campos dentro de parameter_tax_credit **
    const taxRequiredFields = [
        'special_invoice', 'normal_invoice', 'does_not_generate_retencion', 'if_it_generates_retention',
        'iva_subject_to_withholding', 'iva_not_withheld', 'exported_decree_29_89', 'special_taxpayers',
        'export_op_retention', 'local_op_retention'
    ];


    // ** Validar que los campos no sean negativos y que no excedan 100% **
    const validatePercentageField = (fieldName, fieldValue, parent = 'values') => {
        if (fieldValue < 0) {
            if (parent === 'taxCredit') {
                errors.parameter_tax_credit = { ...errors.parameter_tax_credit, [fieldName]: messageNoNegatives };
            } else {
                errors[fieldName] = messageNoNegatives;
            }
        } else if (fieldValue > 100) {
            if (parent === 'taxCredit') {
                errors.parameter_tax_credit = { ...errors.parameter_tax_credit, [fieldName]: messageMax100 };
            } else {
                errors[fieldName] = messageMax100;
            }
        }
    };

    // Validar porcentaje de campos que no deben ser negativos ni mayores a 100% en parameter_tax_credit
    taxRequiredFields.forEach(field => {
        if (taxCredit[field] !== undefined) {
            validatePercentageField(field, taxCredit[field], 'taxCredit');
        }
    });

    // Validar porcentaje de otros campos en values
    ['fac_special', 'purchase_suppliers', 'export_sales', 'local_sales', 'var_yield', 'var_skim', 'var_lost',
     'var_clot_wash', 'var_clot_traps', 'var_clot_centrifuged', 'var_margin_latex', 'var_margin_solidos',
     'var_margin_skim', 'var_max_ability_tanks', 'var_cant_rubber_solido', 'var_skim_mix_solido', 'var_dif_paymen_vs_prod'].forEach(field => {
        if (values[field] !== undefined) {
            validatePercentageField(field, values[field]);
        }
    });

    // ** Validar que Porcentaje exportado decreto 29-89 y Contribuyentes especiales no superen individualmente el 100% **
    validatePercentageField('exported_decree_29_89', taxCredit.exported_decree_29_89, 'taxCredit');
    validatePercentageField('special_taxpayers', taxCredit.special_taxpayers, 'taxCredit');

    // ** Nueva Validación: IVA sujeto a retención + IVA no retenido deben sumar 100% **
    const totalIva = (taxCredit.iva_subject_to_withholding || 0) + (taxCredit.iva_not_withheld || 0);
    if (totalIva !== 100) {
        errors.parameter_tax_credit = {
            ...errors.parameter_tax_credit,
            iva_subject_to_withholding: messageIvaNotEqualTo100,
            iva_not_withheld: messageIvaNotEqualTo100
        };
    }

    // Validaciones previas para otros campos o arrays, como prod_system, si están fuera de parameter_tax_credit
    if (typeof values.prod_system !== 'undefined') {
        const elementsArrayErrors = [];
        values.prod_system.forEach((element, elementIndex) => {
            const elementErrors = {};
            if (!element || !element.name) {
                elementErrors.name = message;
                elementsArrayErrors[elementIndex] = elementErrors;
            }
            if (!element || !element.volumen) {
                elementErrors.volumen = message;
                elementsArrayErrors[elementIndex] = elementErrors;
            }
            if (!element || !element.cost) {
                elementErrors.cost = message;
                elementsArrayErrors[elementIndex] = elementErrors;
            }
        });
        if (elementsArrayErrors.length) {
            errors.prod_system = elementsArrayErrors;
        }
    }

    return errors;
};

export default validate;
