import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/investments/investments';
import { getMe } from "../../../../../../redux/modules/cuenta/login";
import InvestmentsCreate from './InvestmentsCreate';

const mstp = state => {
    return {...state.investments}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(InvestmentsCreate)
