import React from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderNumberKG } from '../../../../Utils/renderField/renderField';


let TransportForm = props => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex justify-content-center">
                    <br />
                    <br />
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="name" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="name" component={renderField} type="text" className="form-control" placeholder='Nombre' />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="description" className="m-0">DESCRIPCIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="description" component={renderField} type="text" placeholder='Descripción' className="form-control" />
                        </div>
                    </div>
                </div>

                <div className="row col-12 p-0">
                    <div className='col-md-6 p-0 '>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="capacity_latex" className="m-0">CAPACIDAD DE KILOS PARA LATEX</label>
                        </div>
                        <div className='col-md-12 text-center'>
                            <Field
                                name="capacity_latex"
                                type="text"
                                component={renderNumberKG}
                                className="form-control"
                                decimalScale={2}
                                placeholder='0.00 kg'
                            />
                        </div>
                    </div>
                    <div className='col-md-6 p-0 '>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="capacity_solidos" className="m-0">CAPACIDAD DE KILOS PARA SOLIDOS</label>
                        </div>
                        <div className='col-md-12 text-center'>
                            <Field
                                name="capacity_solidos"
                                type="text"
                                component={renderNumberKG}
                                className="form-control"
                                decimalScale={2}
                                placeholder='0.00 kg'
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-12 p-0">
                    <div className='col-md-6 p-0 '>
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="capacity_skim" className="m-0">CAPACIDAD DE KILOS PARA SKIM</label>
                        </div>
                        <div className='col-md-12 text-center'>
                            <Field
                                name="capacity_skim"
                                type="text"
                                component={renderNumberKG}
                                className="form-control"
                                decimalScale={2}
                                placeholder='0.00 kg'
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/transport">CANCELAR</Link>

                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
TransportForm = reduxForm({
    form: 'TransportForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'description': validators.exists()('Campo requerido.'),

        })
    }
})(TransportForm);
export const TransportUpdateForm = reduxForm({
    form: 'EditarTransportForm',
    validate: data => {
        return validate(data, {
            'name': validators.exists()('Campo requerido.'),
            'storage_capacity': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(TransportForm);

export default TransportForm
