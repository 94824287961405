import React, { useState } from 'react';
import { Field, reduxForm, FieldArray, submit } from 'redux-form';
import CardPunteado from "../../../../Utils/Cards/CardPunteado";
import ShoppingTable from "./Tables/ShoppingTable"
import ShoppingLatexTable from './Tables/ShoppingLatexTable';
import ShoppingChipaTable from './Tables/ShoppingChipaTable';
import { renderField, renderFieldDisabled } from "../../../../Utils/renderField/renderField"
import { Link } from 'react-router-dom';
import CategoriaModal from "./Modal/ModalShopping";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');
import validate from './validate';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import CardFormNoBorder from '../../../../Utils/Cards/CardFormNoBorder';
import { connect } from 'react-redux';
import ConstantsTable from './Tables/ConstantsShopping'

const renderTable = ({ fields, dollar_exchange, change, step }) => (
    <div>
        {fields.map((row, index) => (
            <div key={index} className='col-sm-12 p-0'>
                <ShoppingTable fieldName={`${row}.generic_row`} dollar_exchange={dollar_exchange} step={step} change={change} />
            </div>
        ))}
    </div>
);

const renderCategory = ({ fields, dollar_exchange, openModal, closeModal, open, change, step }) => {

    const delete_category = (index) => {
        let message = '¿Estás seguro de confirmar todos los cambios?';
        const SwalMod = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-primary btn-modal',
                cancelButton: 'btn btn-outline-dark mr-5 btn-modal',
                content: '',
                title: 'darck',
                popup: 'custom-modal-size',
            },
            buttonsStyling: false,
            showCloseButton: true,
            focusConfirm: false,
            focusCancel: false,
            padding: "1em",
        });

        SwalMod.fire({
            title: 'ADVERTENCIA',
            html: 'Si eliminas la categoriía perderás toda la información, <br/>' + message,
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'CONTINUAR',
            cancelButtonText: 'CANCELAR',
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                fields.remove(index)
            }
        });
    }

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [message, setMessage] = useState('');

    const handleNameChange = (value) => {
        setName(value);
    };

    const handleDescriptionChange = (value) => {
        setDescription(value);
    };

    const addCategory = () => {
        if ([name, description].includes('')) {
            setMessage('Todos los campos son obligatorios')
            setTimeout(() => {
                setMessage('')
            }, 3000);
            return
        }
        closeModal()
        fields.push({ name: name, description: description, category_table: [{}] })
    }
    return (
        <div>
            <CategoriaModal open={open} closeModal={closeModal} handleNameChange={handleNameChange} handleDescriptionChange={handleDescriptionChange} addCategory={addCategory} message={message} />
            {fields.map((table, index) => (
                <div key={index} className='col-sm-12 p-0'>
                    <div className="mt-2">
                        <Field
                            name={`${table}.name`}
                            component={renderFieldDisabled}
                            width="50%"
                            applyStyles={false}
                            isTextarea={false}
                        />
                        <Field
                            name={`${table}.description`}
                            component={renderField}
                            style={{ display: 'none' }}
                            width="50%"
                        />
                        {step === 1 &&
                            <div type="button" className="btn btn-light btn-lg btn-block d-flex justify-content-end mb-2 eliminar-tab-generica" onClick={() => delete_category(index)}>
                                ELIMINAR RUBRO
                            </div>
                        }
                    </div>
                    <FieldArray name={`${table}.category_table`} dollar_exchange={dollar_exchange} change={change} component={renderTable} step={step} />
                </div>
            ))}
            {step === 1 &&
                <div className='mb-4'>
                    <CardPunteado>
                        <div className='p-4 mb-4'>
                            <button type="button" className="btn btn-categories " onClick={(e) => { e.preventDefault(); openModal() }}>
                                + AGREGAR RUBRO
                            </button>
                        </div>
                    </CardPunteado>
                </div>
            }
        </div>
    );
}

let ShoppingForm = props => {
    const { handleSubmit, editar, item, openModal, closeModal, open, formValues, data, step, setStep } = props;
    const [localErrors, setLocalErrors] = useState(false);

    const handleNextStep = () => {
        let validateData = formValues.values
        const errors = validate(validateData);
        if (Object.keys(errors).length > 0) {
            setLocalErrors(true);
            setTimeout(() => {
                setLocalErrors(false);
            }, 3000);
            return;
        }
        setStep(step + 1);
    };
    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardFormNoBorder
                    icon="Side_activos/Compras.svg"
                    titulo="PRESUPUESTO DE COMPRAS"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12">
                            <h5 className="mb-4"><strong>PASO {step} de 2</strong> {step === 1 ? "EDICIÓN" : "CONFIRMACIÓN"}</h5>
                        </div>
                    </div>
                </CardFormNoBorder>
                {data.is_farm_budget_completed &&
                    data.is_sales_budget_completed && (
                        <React.Fragment>
                            <CardEmpty isMarginButtom>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            <strong>
                                                COSTOS POR COMPRAS DE LATEX
                                            </strong>
                                        </h5>
                                    </div>
                                </div>
                                <ShoppingLatexTable {...props} step={step} />
                            </CardEmpty>
                            <CardEmpty isMarginButtom>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            <strong>
                                                COSTOS POR COMPRAS DE CHIPA
                                            </strong>
                                        </h5>
                                    </div>
                                </div>
                                <ShoppingChipaTable {...props} step={step} />
                            </CardEmpty>
                            <CardEmpty isMarginButtom >
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h6 className="mb-4"><strong>Fletes y comisiones de compras</strong></h6>
                                    </div>
                                </div>
                                <ConstantsTable
                                    {...props}
                                />
                            </CardEmpty>
                            <CardEmpty>
                                <div className="col-md-3 p-0 mt-2">
                                    <div className="md-12">
                                        <h5 className="mb-4">
                                            RUBROS -PRESUPUESTO DE COMPRAS
                                        </h5>
                                    </div>
                                </div>
                                {localErrors && (
                                    <div className="alerta error_mensaje btn-flotante">
                                        {" "}
                                        Todos los conceptos son requeridos{" "}
                                    </div>
                                )}
                                <FieldArray
                                    name="category_budget"
                                    step={step}
                                    dollar_exchange={item}
                                    openModal={openModal}
                                    closeModal={closeModal}
                                    open={open}
                                    component={renderCategory}
                                    change={props.change}
                                />
                            </CardEmpty>
                            {step === 1 && (
                                <div className="buttons-box mt-5">
                                    <button
                                        type="button"
                                        className="btn btn-primary m-4 btn-modal"
                                        onClick={() => handleNextStep()}
                                    >
                                        SIGUIENTE
                                    </button>
                                </div>
                            )}
                            {step === 2 && (
                                <div className="buttons-box mt-5">
                                    <div>
                                        <h6 className="mb-4">
                                            <strong>
                                                Revisa que toda tu información
                                                se encuentre de forma correcta
                                            </strong>
                                        </h6>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary m-4 btn-modal"
                                        onClick={() => setStep(step - 1)}
                                    >
                                        REGRESAR
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-4 btn-modal"
                                    >
                                        CONFIRMAR
                                    </button>
                                </div>
                            )}
                        </React.Fragment>
                    )}
            </div>
        </form>
    )
};

const mapStateToProps = (state) => {
    const formValues = state.form.ShoppingForm || {};
    return { formValues };
};

ShoppingForm = reduxForm({
    form: 'ShoppingForm',
    validate,
})(ShoppingForm);


export default connect(mapStateToProps)(ShoppingForm);