import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import Swal from "sweetalert2";

const alerta = require("assets/img/icons/activo.png");

const url = "solid-plant";
const form = "SolidPlantForm";
// ------------------------------------
// Constants
// ------------------------------------

export const constants = {
    LOADER: "LOADER",
    DATA: "DATA",
    ITEM_DATA: "ITEM",
    PAGE: "PAGE",
    ORDERING: "ORDERING",
    SEARCH: "SEARCH",
    DATA_MODAL: "DATA_MODAL",
};
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setItem = item => ({
    type: constants.ITEM_DATA,
    item,
});
const setDataModal = data_modal => ({
    type: constants.DATA_MODAL,
    data_modal,
});


const confirm_finca_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-primary btn-modal",
            content: "",
            title: "darck",
            popup: "custom-modal-size",
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: "FELICIDADES",
        text: "Tu formulario se ha realizado de forma exitosa",
        imageUrl: alerta,
        imageAlt: "Alerta",
        confirmButtonText: "CONTINUAR",
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(push(`/planta-solidos/configuration`));
            dispatch(read());
        }
        dispatch(push(`/planta-solidos/configuration`));
        dispatch(read());
    });
};

// -----------------------------------
// Actions
// -----------------------------------

const initializeEmptyForm = (formName) => (dispatch) => {
    dispatch(initializeForm(formName, {}));
    dispatch(setDataModal({}));
};
const initializeKGForm = (data_modal) => (dispatch) => {
    dispatch(initializeForm('LatexFormKG', data_modal.data_kg_secos));
};

const read = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_solid_plant`)
        .then((response) => {
            const {data_kg_secos } = response;
            dispatch(initializeForm('LatexFormKG', data_kg_secos));
            dispatch(setData(response));
        })
        .catch((error) => {
            NotificationManager.error(
                error && error.detail ? error.detail : "Error en la edición",
                "ERROR",
                3000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const readAllShow = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_solid_plant_show_all`)
        .then((response) => {
            const { results } = response;
            dispatch(initializeForm(form, results));
            dispatch(setData(response));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const readShow = (id) => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_solid_plant_show`, params)
        .then((response) => {
            const { results } = response;
            dispatch(initializeForm('CategoriaModal', results));
            dispatch(setDataModal(response));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error al obtener datos de edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const readEdit = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.id = id;
    // sin backend
    api.get(`${url}/get_planta_edit`, params)
        .then((response) => {
            const { results } = response;
            dispatch(initializeForm("CategoriaModal", results));
            dispatch(setDataModal(response));
        })
        .catch((error) => {
            NotificationManager.error(
                error && error.detail
                    ? error.detail
                    : "Error al obtener datos de edición",
                "ERROR",
                3000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const show = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    params.id = id;
    api.get(`${url}/get_finca`, params).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(setData(response.results));
        dispatch(initializeForm('AdministrativosEnCursoVer', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`administrative/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm(form, response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`, data)
        .then(() => {
            confirm_finca_save(dispatch);
        })
        .catch((error) => {
            NotificationManager.error(
                error && error.detail ? error.detail : "Error en la creación",
                "ERROR",
                3000
            );
            console.log(error, "Error");
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data)
        .then(() => {
            confirm_finca_save(dispatch);
        })
        .catch(() => {
            NotificationManager.error(
                error && error.detail ? error.detail : "Error en la edición",
                "ERROR",
                3000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`)
        .then(() => {
            dispatch(read());
            NotificationManager.success("Registro eliminado", "Éxito", 3000);
        })
        .catch((error) => {
            NotificationManager.error(
                error && error.detail
                    ? error.detail
                    : "Error en la transacción",
                "ERROR",
                3000
            );
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setTab = tab => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: constants.TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getAdministrativos());
    } else {
        dispatch(getAdministrativos());
    }
};

export const actions = {
    editar,
    read,
    getDollar,
    setTab,
    show,
    crear,
    readEdit,
    eliminar,
    readShow,
    initializeEmptyForm,
    readAllShow,
    initializeKGForm
};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.DATA_MODAL]: (state, { data_modal }) => {
        return {
            ...state,
            data_modal,
        };
    },
};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    data_modal: {},
    item: {},
};

export default handleActions(reducers, initialState);
