import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../../Utils/Grid';
import { months } from '../../../../Utils/months';
import ver_icon from 'assets/img/icons/ver.svg'
const Listado = (props) => {
    const { data, loader, onSortChange, openModal, readShow } = props;
    
    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Total') {
            return 'celda-totales';
        }
        return '';
    };
    const meses = months;

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
        >
            {index + 1}
        </TableHeaderColumn>
    ));
    const abrirModalYConsultar = (id) => {
        readEdit(id);
        openModal();
        isEdition()
    };
    
    return (
        <div>
            <Grid
                hover
                striped
                data={!data || !Array.isArray(data.results) ? [] : data }
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    isKey
                    columnClassName={columnaClaseTotal}
                    width='200'
                >
                    RUBRO
                </TableHeaderColumn>
                {columnas}
                <TableHeaderColumn
                    dataField="total"
                    dataSort
                    dataFormat={formatearNumero}
                    columnClassName={columnaClaseTotal}
                    width='150'
                >
                    TOTAL
                </TableHeaderColumn>
            </Grid>
            <br />
        </div>
    )
};

export default Listado;
