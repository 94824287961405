const validate = values => {
    const errors = {};
    const message = 'Campo requerido';

    // Validate the 'name' field
    if (!values.name) {
        errors.name = message;
    }

    if (Array.isArray(values.investments_budget_row)) {
        const investmentsRowErrors = values.investments_budget_row.map((row, index) => {
            const rowErrors = {};

            if (!row.name) {
                rowErrors.name = message;
            }

            return Object.keys(rowErrors).length ? rowErrors : null;
        }).filter(rowErrors => rowErrors !== null);

        if (investmentsRowErrors.length) {
            errors.investments_budget_row = investmentsRowErrors;
        }
    }

    return errors;
};

export default validate;
