import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { months } from '../../../../Utils/months';
import Grid from '../../../../Utils/Grid';
import ver_icon from 'assets/img/icons/ver.svg'

const Listado = (props) => {
    const { data, loader, onSortChange, openModal, readShow } = props;

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero).replace('$', '$ ');
    };
    const columnaClaseTotal = (cell, row) => {
        if (row.name === 'Totales') {
            return 'celda-totales';
        }
        return '';
    };
    const columnaAcciones = (cell, row) => {
        if (row.name === 'Totales' || row.name === 'CONTROL CALIDAD Y MANTENIMIENTO') {
            return null; // No renderiza nada para la fila 'Totales'
        }
        return (
            <div className="d-flex justify-content-center">
                <button
                    className=""
                    onClick={() => abrirModalYConsultar(cell)}
                    style={{ border: 'none', background: 'none' }}
                >
                    <img src={ver_icon} alt="Ver" className="action_img" />
                </button>
            </div>
        );
    };

    const meses = months;

    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            columnClassName={columnaClaseTotal}
            width='130'
            
        >
            {index + 1}
        </TableHeaderColumn>
    ));
    
    const abrirModalYConsultar = (id) => {
        readShow(id);
        openModal();
    };

    return (
        <div>
            <Grid
                hover
                striped
                data={!data || !Array.isArray(data.results) ? [] : data }
                loading={loader}
                onSortChange={onSortChange}
                pagination={false}
                className="pb-3"
            >
                <TableHeaderColumn
                    dataField="name"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    width='190'
                >
                    PRESUPUESTO
                </TableHeaderColumn>
                {columnas}
                <TableHeaderColumn
                    dataField="total"
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={formatearNumero}
                    width='160'
                >
                   TOTAL
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width='100'
                    dataSort
                    columnClassName={columnaClaseTotal}
                    dataFormat={columnaAcciones}
                >
                    ACCIONES
                </TableHeaderColumn>
            </Grid>
            <br />
        </div>
    );
};

export default Listado;
