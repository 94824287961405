import { divide } from 'lodash';
import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import Listado from "./Listado"
import { Link } from 'react-router-dom';

export default class AdministrativosList extends Component {
    componentDidMount() {
        const { getAdministrativos, page } = this.props;
        getAdministrativos(page);
    }
    render() {
        return (
            <div className="mb-4 col-12">
                <Card noShadow
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO ADMINISTRATIVO"
                    subtitulo="CONFIGURACIÓN"
                >
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6">
                            <h4 className="mr-2 titulo_principal">PRESUPUESTO ADMINISTRATIVO</h4>
                        </div>
                        <div className="col-md-6 d-grid gap-2 d-md-flex justify-content-md-end">
                            <Link className="btn btn-primary mb-4" to="configuration/crear">Agregar</Link>
                        </div>
                    </div>

                    <Listado {...this.props} />
                </Card>
            </div>
        )
    }
}