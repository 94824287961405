import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {reducer as formReducer} from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import perfiles from './modules/perfiles/perfiles';
import configuracion from './modules/configuracion';
import roles from './modules/roles/roles';
import emailsbysendsReducer from './modules/envioNotificaciones';
import parameters from './modules/parameters/parameters';
import administrativos from './modules/administrativos/administrativos';
import customers from './modules/admin/customers';
import ports from './modules/admin/ports';
import packings from './modules/admin/packing';
import sales from './modules/sales/sales';
import transports from './modules/admin/transport';
import shopping from './modules/shopping/shopping';
import finca from './modules/finca/finca';
import planta_latex from './modules/planta_latex/planta_latex';
import solidPlant from './modules/solidPlant';
import logistics_and_export from './modules/logistics_and_export/logistics_and_export';
import investments from './modules/investments/investments';
import debt_management from './modules/debt_management/debt_management';
import tax_credit from './modules/tax_credit/tax_credit'
export default combineReducers({
    // Se quedan
    usuarios,
    register,
    roles,
    login,
    notificaciones,
    perfiles,
    profile,
    routing,
    emailsbysendsReducer,
    form: formReducer,
    configuracion,
    parameters,
    administrativos,
    customers,
    ports,
    packings,
    sales,
    transports,
    shopping,
    finca,
    planta_latex,
    solidPlant,
    logistics_and_export,
    investments,
    debt_management,
    tax_credit
});
