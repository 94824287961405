import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/admin/packing';
import {getMe} from "../../../../../../redux/modules/cuenta/login";
import CreatePacking from './CreatePacking';

const mstp = state => {
    return {...state.packings}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(CreatePacking)
