import React from 'react';
import { Field } from 'redux-form';
import { AsyncSelectField, renderCurrency, renderField, renderFieldCheck } from '../../../../../Utils/renderField/renderField';

const RecetaRow = ({ system, index, fields, getTypeOfTransport }) => {

    return (
        <tr key={index}>
            <td className="sin-borde-top" style={{ width: '15%' }}>
                <Field
                    name={`${system}.name`}
                    type="text"
                    component={renderField}
                    className="form-control"
                    placeholder="Rubro"
                />
            </td>
            {<td className="sin-borde-top" style={{ width: '30%' }}>
                <Field
                    name={`${system}.type_of_transport`}
                    component={AsyncSelectField}
                    loadOptions={getTypeOfTransport}
                    valueKey="id"
                    labelKey="name"
                    className="form-control"
                    isMulti
                />
            </td>}
            {<td className="sin-borde-top" style={{ width: '30%' }}>
                <Field
                    name={`${system}.cost`}
                    type="number"
                    component={renderCurrency}
                    placeholder={'Q 0.0000'}
                    decimalScale={4}
                />
            </td>}
            <td className='sin-borde-top' style={{ width: '20%' }}>
                <Field
                    name={`${system}.is_iva_credit`}
                    component={renderFieldCheck}
                    className='form-control'
                />
            </td>
            <td className="text-center sin-borde-top" style={{ width: '10%', position: 'relative' }}>
                <div style={{ display: 'flex' }}>
                    <div>
                        <img
                            src={require('assets/img/icons/Accion-Eliminar.svg')}
                            alt="Borrar"
                            title="Borrar"
                            className="action_img"
                            onClick={() => fields.remove(index)}
                        />
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default RecetaRow;
