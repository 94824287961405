import React, { useEffect } from 'react';
import CardForm from '../../../../../Utils/Cards/CardForm';
import { Field, reduxForm } from 'redux-form';
import InvestmentsInProgressTable from "./InvestmentsInProgressTable"
import { renderFieldDisabled } from "../../../../../Utils/renderField/renderField"
import { Link } from 'react-router-dom';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

let InvestmentsinProgressShow = props => {
    const { handleSubmit, item} = props;

    useEffect(() => {
        props.show(props.match.params.id)

    }, [])



    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO DE INVERSIONES"
                    subtitulo="RESUMEN"
                    subtituloForm="VER"
                    col="12"
                    noShadow
                >
                    <div className="col-md-3 p-0 mt-2">
                        <div className="md-12  form-group">
                            <Field
                                name="name"
                                component={renderFieldDisabled}
                                type="text"
                                className="form-control"
                                width="50%"
                                applyStyles={false}
                                isTextarea={false}
                            />

                        </div>
                    </div>
                    <div className='mt-4 mb-4'>
                        <InvestmentsInProgressTable {...props} dollar_exchange={item} />
                    </div>
                </CardForm>
                <div className='buttons-box mt-5'>
                    <Link className="btn btn-outline-dark mr-3 p-2 btn-modal m-5" type="button" to="/inversiones/summary">REGRESAR</Link>
                </div>
            </div>
        </form>
    )
};
InvestmentsinProgressShow = reduxForm({
    form: 'InvestmentsinProgressShow',
})(InvestmentsinProgressShow);

export default InvestmentsinProgressShow;