import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/administrativos/administrativos';
import AdministrativosEnCursoVer from "./AdministrativosEnCursoVer";


const ms2p = (state) => {
  return {
    ...state.administrativos,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(AdministrativosEnCursoVer);
