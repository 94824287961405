import React from 'react';
import CardForm from '../../../../Utils/Cards/CardForm';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import validate from './validate';
import InvestmentsDetailTable from './InvestmentsTable'
import { renderField } from '../../../../Utils/renderField/renderField';

let InvestmentsForm = props => {
    const { handleSubmit, editar, item } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-sm-12 p-0'>
                <CardForm
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO DE INVERSIONES"
                    subtitulo="CONFIGURACIÓN"
                    subtituloForm={editar ? "EDITAR" : "NUEVO"}
                    col="12"
                    noShadow
                >
                    <div className="col-12 p-0 mt-2">
                        <div className='d-flex flex-wrap'>
                            <div className='mr-4 w-25'>
                                <label htmlFor="name" className="m-0">Nombre del presupuesto</label>
                                <div className="form-group">
                                    <Field
                                        id='name'
                                        name="name"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <InvestmentsDetailTable {...props} dollar_exchange={item} />
                </CardForm>
                <div className='buttons-box mt-5'>
                    <Link className="btn btn-outline-dark mr-3 p-2 btn-modal"
                        type="button"
                        to="/inversiones/configuration">
                        REGRESAR
                    </Link>
                    <button type="submit" className="btn btn-primary m-4 btn-modal">
                        CONFIRMAR
                    </button>
                </div>
            </div>
        </form>
    );
};

InvestmentsForm = reduxForm({
    form: 'InvestmentsForm',
    validate,
})(InvestmentsForm);

export default InvestmentsForm;
