import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../../Utils/Grid/StandardActions";
import Grid from "../../../../Utils/Grid";
import { months } from '../../../../Utils/months';


const ListSubProduction = ({ data, loader, onSortChange }) => {

    const formatearNumero = (dato) => {
        const formatoMoneda = new Intl.NumberFormat('es-GT', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const numero = Number(dato);
        return formatoMoneda.format(numero) + ' kg';
    }

    const meses = months
    const columnas = meses.map((mes, index) => (
        <TableHeaderColumn
            key={index}
            dataField={mes}
            dataSort
            dataFormat={formatearNumero}
            width='140'
        >
            {index + 1}
        </TableHeaderColumn>
    ));

    return (
        <Grid hover striped data={data} loading={loader} onSortChange={onSortChange} pagination={false} className="pb-3">
            <TableHeaderColumn 
            dataField="name" 
            dataSort
            width='110'
            >
                PRODUCTO
            </TableHeaderColumn>
            {columnas}
            <TableHeaderColumn 
            dataField="id" 
            dataAlign="center" 
            isKey 
            width='100' 
            dataSort 
            dataFormat={standardActions({ editar: "sub" })}
            >
                ACCIONES
            </TableHeaderColumn>
        </Grid>
    );
};

export default ListSubProduction;
