import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderDollar, renderFieldDisabled } from '../../../../Utils/renderField/renderField';
import { api } from "api";
import { PRODUCT } from "../../../../../../utility/constants";

const RenderRows = ({ fields }) => (
    <tbody>
        {fields.map((subProduct, index) => (
            <tr key={index}>
                <td className="sin-borde-top">
                    <Field
                        name={`${subProduct}.subproduct`}
                        component={renderFieldDisabled}
                        className="form-control"
                    />
                </td>
                <td>
                    <Field
                        name={`${subProduct}.cost`}
                        component={renderDollar}
                        type="number"
                        placeholder={'$ 0.0000'}
                        decimalScale={4}
                    />
                </td>
            </tr>
        ))}
    </tbody>
);

const CustomerCommissionsSolidos = ({ fields }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded && fields.length === 0) { 
            api.get('sub-product-sales', { product_sales__product: PRODUCT["SOLIDOS"] })
                .then(response => {
                    if (response) {
                        response.results.forEach(subProduct => {
                            fields.push(subProduct);
                        });
                        setLoaded(true);
                    }
                })
                .catch(error => {
                    console.error("Error loading subproducts:", error);
                });
        }
    }, [loaded, fields.length]);

    return (
        <div className="tab-finca">
            <div className="col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table mb-0">
                        <thead>
                            <tr>
                                <th>SUBPRODUCTO</th>
                                <th>COMISIÓN</th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className="tabla-con-borde">
                    <table className="table table-sm m-0 table-striped">
                        <RenderRows fields={fields} />
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerCommissionsSolidos;
