import React, { Component } from 'react'
import Card from "Utils/Cards/Card"
import AdministrativosEnCurso from "./InvestmentsInPorgressList"
import Tabs, { TabPane } from "rc-tabs";
import CardEmpty from '../../../../Utils/Cards/CardEmpty';

export default class InvestmentsSummary extends Component {
    componentDidMount() {
        const { getInvestmentsDetail, page } = this.props;
        getInvestmentsDetail(page);
    }

    render() {
        const { setTab, tab, item } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card noShadow
                    icon="Side_activos/Admin.svg"
                    titulo="PRESUPUESTO DE INVERSIONES"
                    subtitulo="RESUMEN"
                    noBorder
                >
                    <Tabs
                        activeKey={tab}
                        onChange={tab => { setTab(tab) }}
                    >
                        <TabPane tab="EN CURSO" key="EN_CURSO">
                            <CardEmpty noShadow>
                                <div className="row col-md-12 p-0 mt-2 mb-4">
                                    <div className="col-md-6">
                                        <h4 className="mr-2 titulo_principal">PRESUPUESTO DE INVERSIONES</h4>
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <AdministrativosEnCurso {...this.props} dollar_exchange={item} />
                                </div>
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="PROYECCIONES ANUALES" key="PROYECCIONES_ANUALES">
                            <CardEmpty noShadow>
                                {/* <AdministrativosEnCurso {...this.props} dollar_exchange={item} /> */}
                            </CardEmpty>
                        </TabPane>
                    </Tabs>

                </Card>
            </div>
        )
    }
}