import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');

let url = 'finca';
let form = 'FincaForm';
// ------------------------------------
// Constants
// ------------------------------------

export const constants = {
    LOADER: 'LOADER_ADMINISTRATIVOS',
    DATA: 'DATA_ADMINISTRATIVOS',
    ITEM_DATA: 'ITEM_ADMINISTRATIVOS',
    PAGE: 'PAGE_ADMINISTRATIVOS',
    ORDERING: 'ORDERING_ADMINISTRATIVOS',
    SEARCH: 'SEARCH_ADMINISTRATIVOS',
    TAB_EN_CURSO: 'TAB_EN_CURSO',
    TAB_PROYECCIONES_ANUALES: 'TAB_PROYECCIONES_ANUALES',
    PAGE_EN_CURSO: 'PAGE_EN_CURSO',
    PAGE_ANUALES: 'PAGE_ANUALES',
    KG_PROYECTADOS: 'KG_PROYECTADOS',
}
// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: constants.LOADER,
    loader,
});
const setData = data => ({
    type: constants.DATA,
    data,
});
const setItem = item => ({
    type: constants.ITEM_DATA,
    item,
});

const setPageEnCurso = page_en_curso => ({
    type: constants.PAGE_EN_CURSO,
    page_en_curso,
});

const setPageFinalizadas = page_anuales => ({
    type: constants.PAGE_ANUALES,
    page_anuales,
});

const confirm_finca_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(push(`/${url}/configuration`));
            dispatch(read());
        } else {
            dispatch(push(`/${url}/configuration`));
            dispatch(read());
        }

    });
}


// -----------------------------------
// Actions
// -----------------------------------

const read = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_finca`)
        .then((response) => {
            const { results } = response;
            dispatch(initializeForm(form, results));
        })
        .catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


const show = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_finca`, params).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(setData(response.results));
        dispatch(initializeForm('AdministrativosEnCursoVer', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`administrative/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm(form, response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};


const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${url}/${id}`, data).then(() => {
        confirm_finca_save(dispatch)
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: constants.TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getAdministrativos())
    } else {
        dispatch(getAdministrativos())
    }
};

export const actions = {
    editar,
    read,
    getDollar,
    setTab,
    show

};

// -----------------------------------
// Reducers
// -----------------------------------

const reducers = {
    [constants.LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [constants.DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [constants.ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [constants.PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [constants.PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [constants.TAB_EN_CURSO]: (state, { tab_encurso }) => {
        return {
            ...state,
            tab_encurso,
        };
    },
    [constants.TAB_PROYECCIONES_ANUALES]: (state, { tab_anuales }) => {
        return {
            ...state,
            tab_anuales,
        };
    },
    [constants.KG_PROYECTADOS]: (state, { kg_proyectados }) => {
        return {
            ...state,
            kg_proyectados,
        };
    },

};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    production_exportable: {},
    kg_proyectados: {},
    item: {},
    tab: 'EN_CURSO',

};
export default handleActions(reducers, initialState)