import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "transports",
    "transport",
    "EditarTransportForm",
    "/transport",
);

export default handleActions(reducers, initialState);
