import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { NotificationManager } from "react-notifications";
import { initialize as initializeForm } from 'redux-form';
import { api } from "api";
import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/activo.png');   

let url = 'administrative'
// ------------------------------------
// Constants
// ------------------------------------
const LOADER = 'LOADER_ADMINISTRATIVOS';
const DATA = 'DATA_ADMINISTRATIVOS';
const ITEM_DATA = 'ITEM_ADMINISTRATIVOS';
const PAGE = 'PAGE_ADMINISTRATIVOS';
const ORDERING = 'ORDERING_ADMINISTRATIVOS';
const SEARCH = 'SEARCH_ADMINISTRATIVOS';
const TAB_EN_CURSO = 'TAB_EN_CURSO';
const TAB_PROYECCIONES_ANUALES = 'TAB_PROYECCIONES_ANUALES';
const PAGE_EN_CURSO = 'PAGE_EN_CURSO';
const PAGE_ANUALES = 'PAGE_ANUALES';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setData = data => ({
    type: DATA,
    data,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPageEnCurso = page_en_curso => ({
    type: PAGE_EN_CURSO,
    page_en_curso,
});

const setPageFinalizadas = page_anuales => ({
    type: PAGE_ANUALES,
    page_anuales,
});


const confirm_administrative_save = (dispatch) => {
    const SwalMod = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-primary btn-modal',
            content: '',
            title: 'darck',
            popup: 'custom-modal-size',
        },
        buttonsStyling: false,
        showCloseButton: true,
        focusConfirm: false,
        padding: "1em",
    });

    SwalMod.fire({
        title: 'FELICIDADES',
        text: 'Tu formulario se ha realizado de forma exitosa',
        imageUrl: alerta,
        imageAlt: 'Alerta',
        confirmButtonText: 'CONTINUAR',
        reverseButtons: true,
    }).then((result) => {
        if (result.value) {
            dispatch(push('/administrativos/configuration'));
        }
        dispatch(push('/administrativos/configuration'));
    });
}

// -----------------------------------
// Actions
// -----------------------------------


const read = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_administrative`,params).then((response) => {
        dispatch(setData(response.results));
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm('AdministrativosForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const show = id => (dispatch) => {
    dispatch(setLoader(true));
    const params = {}
    params.id = id
    api.get(`${url}/get_administrative`,params).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(setData(response.results));
        dispatch(initializeForm('AdministrativosEnCursoVer', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getDollar = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_dollar_exchange`).then((response) => {
        dispatch(setItem(response.dollar_exchange));
        dispatch(initializeForm('AdministrativosForm', response.results));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getAdministrativos = () => ( dispatch ) => {
    dispatch(setLoader(true));
    api.get(`${url}/get_all`).then((response) => {
        dispatch(setData(response));
        dispatch(initializeForm('PresupuestoAdministrativo', response));
        dispatch(setItem(response.dollar_exchange));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id,data) => (dispatch) => {
        dispatch(setLoader(true));
    api.put(`${url}/${id}`,data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/administrativos/configuration'));
    }).catch(() => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${url}`,data).then(() => {
        confirm_administrative_save(dispatch);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la creación', 'ERROR', 3000);
        console.log(error,'Error')
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${url}/${id}`).then(() => {
        dispatch(getAdministrativos());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

//  ----------------
// Administrativo Resumen
// -----------------

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: TAB_EN_CURSO, tab });
    if (tab === "EN_CURSO") {
        dispatch(getAdministrativos())
    } else {
        dispatch(getAdministrativos())
    }
};

export const actions = {
    getAdministrativos,
    editar,
    crear,
    read,
    eliminar,
    getDollar,
    setTab,
    show
    
};
// -----------------------------------
// Reducers
// -----------------------------------
const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },

    [PAGE_EN_CURSO]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [PAGE_ANUALES]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },

    [TAB_EN_CURSO]: (state, { tab_encurso }) => {
        return {
            ...state,
            tab_encurso,
        };
    },
    [TAB_PROYECCIONES_ANUALES]: (state, { tab_anuales }) => {
        return {
            ...state,
            tab_anuales,
        };
    },
    
};
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    administrativos_en_curso: {
        results: [],
        count: 0,
    },
    administrativos_anuales: {
        results: [],
        count: 0,
    },
    page_en_curso: 1,
    page_anuales: 1,
    item: {},
    tab: 'EN_CURSO',
    
};
export default handleActions(reducers, initialState)