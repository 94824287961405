import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './CustomerForm';
import { CustomerUpdateForm } from './CustomerForm';

export default class CreateCustomer extends Component {
  state = {
    editar: false,
    mensaje: 'Agregar cliente',
  };
  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.readEdit(this.props.match.params.id);
      this.setState({
        editar: true,
      });
    }
  }
  actualizar = (data) => {
    this.props.editar(this.props.match.params.id, data)
  };
  getPermissions = () => {
    const { getMe } = this.props;
    getMe();
  }
  render() {
    const { crear } = this.props;
    const { updateData, loader } = this.props;
    return (
      <div className="mb-4 col-12">
        <CardForm
          icon="configuraciones_activo.png"
          titulo="ADMINISTRACIÓN"
          subtitulo="CLIENTES"
          subtituloForm={this.state.editar ? "Editar" : "Nuevo"}
          col="11">
          <LoadMask loading={loader} blur>
            {
              this.state.editar ?
                <CustomerUpdateForm
                  onSubmit={this.actualizar}
                  updateData={updateData}
                  permisos={this.getPermissions} />
                :
                <Form onSubmit={crear} />
            }
          </LoadMask>
        </CardForm>
      </div>
    )
  }
}
